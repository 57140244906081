import React, { useEffect, useState } from 'react'
import { API_URL_ADMIN } from './common';
import './plates.css'
import Table from './Table';
import { useAuth0, User } from "@auth0/auth0-react";
import AdminNav from './AdminNav';

function PlatesAdmin() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const {isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    
    async function getPlates() {
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
            mode: 'cors'
        };

        const request = await fetch(API_URL_ADMIN + `auctions`, requestOptions, {

        }).then((request => request.json()))
        .then((data) => {
            setData(data)
            setLoading(false) // set Loading to false when you have the data
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })
    }

    useEffect(() => {
        getPlates();
    }, []);

    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>Admin</h1>
                    <AdminNav/>
                </div>

                {loading ? (
                    <p>Loading... (DEV NOTE: THIS TAKES UP TO A MINUTE ON THE TEST SERVER)</p>
                ) : (
                    <Table data={data} admin={true}></Table>
                )}
            </div>
        </div>
    )
}

export default PlatesAdmin