import React, { useEffect }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import './App.css';
import './marquee.css';
import './About.css';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'

function _404() {
    const { loginWithRedirect } = useAuth0();
    
    return (
        <div>
           <div id="hero" className="about-hero">
                <div id="hero-text" className="about-hero-text">
                    <h1>Page Not Found</h1>
                    <h2>This page does not exist. You can return to the homepage by clicking the button below.</h2>
                    <div style={{display: "flex", alignItems: "center"}}>   
                        <Link to="/">
                            <div id="action-button-2">
                                <p>Back to Home <span className="arrow">❯</span></p>
                            </div>
                        </Link>
                    </div> 
                </div>

                <div className="about-hero-img"></div>
            </div>

           
        </div>
    )
}

export default _404