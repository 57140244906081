import React, {useState, useEffect} from 'react'
import './App.css';
import logo from './res/logo.png'
import { Link } from 'react-router-dom';
import { useAuth0, User } from "@auth0/auth0-react";
import bell from './res/bell.svg'
import { API_URL, WS_URL } from './common';
import io from 'socket.io-client';
import Loading from './Loading';

function Header() {
    const { loginWithRedirect } = useAuth0();
    const {isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [notifications, setNotifications] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [menu, setMenu] = useState(false);
    const [menu2, setMenu2] = useState(false);
    const [showDot, setShowDot] = useState(false);

    function toggleNotifications(event){
        setShowDot(false);
        setNotifications(!notifications);
    }

    async function handleNav(){
        setMenu(!menu);
        setMenu2(false);
    }

    async function toggleMenu2(){
        setMenu2(!menu2);
    }

    async function getNotifications(){
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
            mode: 'cors'
        };

        const request = await fetch(API_URL + `notifications/${user.sub}`, requestOptions, {

        }).then((request => request.json()))
        .then((data) => {
            setData(data)
            setLoading(false) // set Loading to false when you have the data
            connectToRoom()
            var n = Number(localStorage.getItem('notifications'));
            if(data.length > n){
                setShowDot(true);
            }

            localStorage.setItem('notifications', data.length);
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })
    }

    async function connectToRoom(){
        const socket = io(WS_URL);
        socket.connect();
        socket.emit('joinroom', user.sub);
        socket.on('notification', function (data) {
            setShowDot(true);
            setData(oldArray => [...oldArray,data] );
            var n = Number(localStorage.getItem('notifications'));
            localStorage.setItem('notifications', n + 1);
        });
    }

    useEffect(() => {
        getNotifications();
    }, [user])

    return (
        <>
        <div id="header-wrapper">
            <header id="header">
                <Link to="/"><img id="logo" src={logo} alt="MyRegPlates" /></Link>
                <div id="nav-container">
                    <nav id="nav">
                        <Link to="/plates"><p className="nav-button" id="listen">Buy</p></Link>
                        <Link to="/list"><p className="nav-button">Sell</p></Link>
                        {/*
                        <div className='link-container'>
                            <div className="nav-new-dot">NEW</div>
                            <Link to="/valuation"><p className="nav-button">Free Valuation</p></Link>
                        </div>
                        */}
                        <Link to="/about"><p className="nav-button">About</p></Link>
                        <Link to="/faq"><p className="nav-button">FAQ</p></Link>
                    </nav>
                    <div id="nav2">
                        {!isAuthenticated ? (
                            <>
                            <button className="nav-button-2" onClick={() => loginWithRedirect()}><p className="nav-button">Log in</p></button>
                            <a rel="nofollow" href={`https://auth.myregplates.com/authorize?response_type=code&client_id=jz2Srv6g7oNYPiWJkoI9SoNcnsUzIpPc&redirect_uri=${window.location.origin}&scope=SCOPE&state=STATE&screen_hint=signup`}><p className="nav-button rounded-bg lp-themed">Sign up</p></a>
                            </>
                        ):(
                            <>
                            <div className="icon-bell-container">
                                <button className="notification-button" onClick={() => toggleNotifications()}>
                                    <img src={bell} alt="bell" width="20" height="20" className="icon-bell"></img>
                                    {showDot ? (
                                        <div className="icon-bell-dot"></div>
                                    ):(
                                        null
                                    )}
                                </button>
                            </div>

                            {notifications ? (
                                <>
                                    <div className="notifications-dropdown">
                                        <div className="notification-area">
                                            {loading ? (
                                                <>
                                                <Loading/>
                                                </>
                                            ):(
                                                <>
                                                {data.slice().reverse().map(notification => (
                                                    <Link reloadDocument to={`/plates/${notification.plate}`}>
                                                        <div className="notification" key={notification._id}>
                                                            <p className="notification-text">{notification.icon}</p>
                                                            <p className="notification-text">{notification.text}</p>
                                                        </div>
                                                    </Link>
                                                ))}
                                                </>
                                            )}
                                        </div>
                                        {/*<p className="notifications-view-more">View All</p>*/}
                                    </div>
                                </>
                            ):(
                                null
                            )}
                            
                            <div className="nav-button rounded-bg centered toggle-dropdown">
                                <img className="user-img" src={user.picture} alt="img"></img>
                                {user.nickname}
                            </div>

                            <div className="user-dropdown">
                                <Link to="/my/profile"><p className="user-dropdown-link">👤 Profile</p></Link>
                                <Link to="/my/auctions"><p className="user-dropdown-link">⚖️ Your Auctions</p></Link>
                                <Link to="/my/plates"><p className="user-dropdown-link">⭐ Your Plates</p></Link>
                                <Link to="/my/watchlist"><p className="user-dropdown-link">👀 Your Watchlist</p></Link>
                            </div>
                            </>
                        )}

                    </div>
                    <div id="nav-mobile">
                        {isAuthenticated ? (
                            <div className="icon-bell-container">
                                <button className="notification-button" onClick={() => toggleNotifications()}>
                                    <img src={bell} alt="bell" width="20" height="20" className="icon-bell"></img>
                                    {showDot ? (
                                        <div className="icon-bell-dot"></div>
                                    ):(
                                        null
                                    )}
                                </button>
                            </div>

                        ):(
                            null
                        )}
                            
                        <button onClick={handleNav} className="nav-toggle">
                        
                            {!menu ? (
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" color="white"><path d="M2 6h20v3H2V6z" fill="currentColor"  ></path>,<path d="M2 15h20v3H2v-3z" fill="currentColor" ></path></svg>
                            ):(
                                <p style={{fontSize: "2rem"}}>×</p>
                            )}

                        </button>
                    
                    </div>
                </div>
            </header>
        </div>

        {menu ?(
        <>
        <div id="nav-mobile-links">
            <p className="nav-button mobile-link"><a href="/plates">Buy</a></p>
            <p className="nav-button mobile-link"><a href="/list">Sell</a></p>
            {/*<p className="nav-button mobile-link"><a href="/valuation">Free Valuation</a></p>*/}
            <p className="nav-button mobile-link"><a href="/about">About</a></p>
            <p className="nav-button mobile-link"><a href="/faq">FAQ</a></p>
            {!isAuthenticated ? (
                <>
                <button className="nav-button-2-mobile nav-button mobile-link" onClick={() => loginWithRedirect()}>Log in</button>
                </>
            ):(
                <>
                    <button onClick={toggleMenu2} style={{border: "none", background: "none"}}>
                        <div className="nav-button rounded-bg centered toggle-dropdown mobile-link" style={{marginTop: 0}}>
                            <img className="user-img" src={user.picture} alt="img"></img>
                            {user.nickname}
                        </div>
                    </button>
                </>
            )}
           
        </div>
        </>
        ):(
        null
        )}

        {menu2 ? (
            <div id="nav-mobile-links" className="layer-2">
            <button style={{paddingLeft: "3rem"}} onClick={toggleMenu2} className="nav-toggle"><p style={{fontSize: "1.5rem"}}>❮</p></button>    
            <p className="nav-button mobile-link"><a href="/my/profile">👤 Profile</a></p>
            <p className="nav-button mobile-link"><a href="/my/auctions">⚖️ Your Auctions</a></p>
            <p className="nav-button mobile-link"><a href="/my/plates">⭐ Your Plates</a></p>
            <p className="nav-button mobile-link"><a href="/my/watchlist">👀 Your Watchlist</a></p>
            </div>
        ):(
            null
        )}

            {notifications ? (
                <>
                    <div className="notifications-dropdown mobile-notifications">
                        <div className="notification-area">
                            {loading ? (
                                <>
                                    <Loading/>
                                </>
                            ) : (
                                <>
                                    {data.slice().reverse().map(notification => (
                                        <Link reloadDocument to={`/plates/${notification.plate}`}>
                                            <div className="notification" key={notification._id}>
                                                <p className="notification-text">{notification.icon}</p>
                                                <p className="notification-text">{notification.text}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                null
            )}
        </>
    )
}

export default Header