import React, { useEffect }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import './Error.css';
import logo from './res/logo.png'

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'

function Error() {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        document.title = "Error | MyRegPlates";
    }, [])
    
    return (
        <div className="error-wrapper">
           <div className="error-content">
                <img src={logo} className="error-logo"></img>
                <p className="error-info">An error has occured. Please refresh the page or contact MyRegPlates if this issue persists.
                <br></br>If you wish to report a bug, visit the <a href="/reportbug" style={{textDecoration: "underline"}}>report bug</a> page.</p>
                <a className="error-back" href="/">Back to home</a>
           </div>
           
        </div>
    )
}

export default Error