import React, { useEffect, useState } from 'react'
import { API_URL } from './common';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import { useAuth0, User } from "@auth0/auth0-react";
import './plates.css'
import Table from './Table';
import Loading from './Loading'

function MyAuctions() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const {isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        document.title = "Your Auctions | MyRegPlates";
    }, [])

    async function getPlates() {
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
            mode: 'cors'
        };

        const request = await fetch(API_URL + `auctions/by/${user.sub}`, requestOptions, {

        }).then((request => request.json()))
            .then((data) => {
                setData(data)
                setLoading(false) // set Loading to false when you have the data
            }).catch(err => console.log(err)).then(() => {
                //setLoading(true)
            })
    }

    useEffect(() => {
        getPlates();
    }, []);

    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>My Auctions</h1>
                </div>

                {loading ? (
                    <Loading></Loading>
                ) : (
                    <>
                    <Table data={data} status={true} perspective={1}></Table>
                    {data.length === 0 ? (
                        <p style={{marginTop: "1.5rem", color: "gray", fontWeight: "bold", textJustify: "center" }}>You haven't created any auctions yet. Visit the Sell page to put your plate up for sale!</p>
                    ):(
                        null
                    )}
                    </>
                )}
            </div>
        </div>
    )
}

export default MyAuctions