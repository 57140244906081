import React, { useEffect }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import './App.css';
import './marquee.css';
import './About.css';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'
import { Link } from 'react-router-dom';

function NotFound() {
    const { loginWithRedirect } = useAuth0();
    
    useEffect(() => {
        document.title = "404 | MyRegPlates";
    }, [])

    return (
        <div>
           <div id="hero" className="about-hero">
                <div id="hero-text" className="about-hero-text notfound">
                    <h1 style={{textAlign: "center"}}>This Plate isn't listed on MyRegPlates</h1>
                    <h2 style={{textAlign: "center"}}>Do you own this plate and want to list it for auction? Click the button below to get started! </h2>
                    <div>   
                        <Link to="/list">
                            <div id="action-button-2">
                                <p>List a Plate <span className="arrow">❯</span></p>
                            </div>
                        </Link>
                    </div> 
                </div>
            </div>

           
        </div>
    )
}

export default NotFound