import { NavLink } from "react-router-dom"

function AdminNav(){
    return (
        <div class="admin-nav">
            <NavLink to="/admin" end><p>Plates</p></NavLink>
            <p>|</p>
            <NavLink to="/admin/valuations" end><p>Valuations</p></NavLink>
            <p>|</p>
            <NavLink to="/admin/users" end><p>Users</p></NavLink>
        </div>
    )
}

export default AdminNav