
import './App.css';
import {Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import CreatePlate from './CreatePlate';
import Plates from './Plates';
import Landing from './Landing';
import Plate from './Plate';
import { useAuth0, User } from "@auth0/auth0-react";
import Profile from './Profile';
import MyPlates from './MyPlates';
import MyAuctions from './MyAuctions';
import MyWatchlist from './MyWatchlist';
import PlatesAdmin from './PlatesAdmin';
import PlateAdmin from './PlateAdmin';
import About from './About';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import {useLayoutEffect} from 'react';
import _404 from './404'
import { ErrorBoundary } from "react-error-boundary";
import Error from './Error';
import FAQ from './FAQ';
import Cookies from './Cookies';
import Legal from './Legal';
import DataProtection from './DataProtection';
import Contact from './Contact';
import Support from './Support';
import ReportBug from './ReportBug';
import ReportSubmitted from './ReportSubmitted';
import ComingSoon from './ComingSoon';
import CookieConsent from 'react-cookie-consent';
import { useEffect } from 'react';
import Valuation from './Valuation';
import ValuationSubmitted from './ValuationSubmitted';
import ValuationsAdmin from './ValuationsAdmin';
import ValuationAdmin from './ValuationAdmin';
import UsersAdmin from './UsersAdmin';

function App() {
  const { isAuthenticated, user } = useAuth0();
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 

  useEffect(() => {
    document.title = "The UK's Premier Registration Plate Marketplace | MyRegPlates";
  }, [])
  
  
  return (
    <div id="wrapper">
      <ErrorBoundary fallback={<Error/>}>
        <Header></Header>
          <Wrapper>
            <Routes>
              <Route path="/" element={<Landing/>}/>
              <Route path="/list" element={<CreatePlate/>}/>
              <Route path="/plates" element={<Plates/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/plates/termsandconditions" element={<Legal/>}/>
              <Route path="/plates/:id" element={<Plate/>}/>
              
              <Route path="/valuation" element={<Valuation/>}/>
              <Route path="/valuation/submitted" element={<ValuationSubmitted/>}/>

              <Route path="/faq" element={<FAQ/>}/>
              <Route path="/cookies" element={<Cookies/>}/>
              <Route path="/legal" element={<Legal/>}/>
              <Route path="/dataprotection" element={<DataProtection/>}/>
              <Route path="/contact" element={<Contact/>}/>
              <Route path="/support" element={<Support/>}/>
              <Route path="/reportbug" element={<ReportBug/>}/>
              <Route path="/reportbug/submitted" element={<ReportSubmitted/>}/>
              {isAuthenticated ? (
                <>
                  <Route path="/my/profile" element={<Profile/>}/>
                  <Route path="/my/plates" element={<MyPlates/>}/>
                  <Route path="/my/auctions" element={<MyAuctions/>}/>
                  <Route path="/my/watchlist" element={<MyWatchlist/>}/>

                  <Route path="/admin" element={<PlatesAdmin/>}/>
                  <Route path="/admin/:id" element={<PlateAdmin/>}/>

                  <Route path="/admin/valuations" element={<ValuationsAdmin/>}/>
                  <Route path="/admin/valuations/:id" element={<ValuationAdmin/>}/>

                  <Route path="/admin/users" element={<UsersAdmin/>}/>
                </>
              ):(
                null
              )}
              <Route path='*' element={<_404 />}/>
            </Routes>
          </Wrapper>
        <Footer></Footer>
      </ErrorBoundary>
      <CookieConsent
      location="bottom"
      buttonText="Accept"
      style={{opacity: "0.9"}}
      containerClasses="cookie-popup"
      contentClasses="cookie-text"
      buttonClasses='cookie-button'
      buttonStyle={{opacity: "1.0", color: "white", background: "#013C77", fontWeight: "bold", borderRadius: "0.75rem"}}
      expires={150}
      >
        This website uses cookies to enhance the user experience. By continuing to use this site you consent to our usage of cookies. <a href="/cookies" style={{textDecoration: "underline"}}>Cookie Policy</a>
      </CookieConsent>
    </div>
  );
}

export default App;
