import React, { useEffect, useState } from 'react'
import { API_URL } from './common';
import { Link, useSearchParams } from 'react-router-dom';
import Countdown from 'react-countdown';
import { useAuth0, User } from "@auth0/auth0-react";
import './plates.css'
import './profile.css'
import verified from './res/verified.png'
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css'
import 'react-credit-cards-2/es/styles-compiled.css';
import Cards from 'react-credit-cards-2';
import { Tooltip } from 'react-tooltip'
import './Form.css'
import Loading from './Loading';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe} from '@stripe/react-stripe-js';
import SetupForm from './SetupForm';

function Profile() {
    const [loading, setLoading] = useState(true);
    const [bankForm, setBankForm] = useState(false);
    const [cardForm, setCardForm] = useState(false);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const {isAuthenticated, user, logout, getAccessTokenSilently } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [status, setStatus] = useState();
    const [status2, setStatus2] = useState();
    const [state, setState] = useState({
        number: '',
        expiry: '',
        expiry2: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const [showCard, setShowCard] = useState(true);
    const [showBank, setShowBank] = useState(true);
    const [showNick, setShowNick] = useState(true);
    const [showPass, setShowPass] = useState(true);
    const [showMail, setShowMail] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);
    const [cardSuccess, setCardSuccess] = useState(false);
    const [bankSuccess, setBankSuccess] = useState(false);
    const [userSuccess, setUserSuccess] = useState(false);
    const [mailSuccess, setMailSuccess] = useState(false);

    const stripePromise = loadStripe('pk_live_51Mik6vAo4UI43guxq1sEkkw7uruWiXHax6HzaW8i5oi8iJJCyi5m70788cJtauAAd1BzmPNE4GbmkllucQFaM0Rb00FdH4gAYW');
    const [intent, setIntent] = useState({});
    const [options, setOptions] = useState({});

    async function getIntent(){
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
            mode: 'cors'
        };

        const request = await fetch(API_URL + `payment/getintent/${user.sub}`, requestOptions, {
        }).then((request => request.json())).then(async (data) => {
            await setIntent(data);
            //console.log(data);
            await setOptions({clientSecret: data.client_secret});
            //console.log(options);
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })
    }

    async function handleSubmitPass(event) {
        event.preventDefault();
        setShowCard(false);

        var passData = {};
        passData.email = user.email;
        passData.id = user.sub;

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken },
          body: JSON.stringify(passData),
          mode: 'cors'
        };
    
        await fetch(API_URL + "users/changepassword", requestOptions).then(
          response => response.json()
        ).then((result) => {
            setStatus2(result);
            setShowCard(true);
            setShowPass(false);
        }).catch((error) => {
          setStatus(error);
          setShowCard(true);
        });
    }

    async function handleSubmitMail(event) {
        event.preventDefault();
    
        setShowMail(false);
        var mailData = {};
        mailData.id = user.sub;
        mailData.email = event.target.tosinput.checked;
    
        console.log(JSON.stringify(mailData));
    
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken },
          body: JSON.stringify(mailData),
          mode: 'cors'
        };
    
        await fetch(API_URL + "users/toggleemail", requestOptions).then(
          response => response.json()
        ).then((result) => {
          //setStatus(result);
          //setShowCard(true);
          if(result.includes('successfully')){
            setMailSuccess(true);
          }
        }).catch((error) => {
          setStatus(error);
          setShowCard(true);
        });
    }

    async function handleSubmitBank(event) {
        event.preventDefault();
    
        var bankData = {};
        setShowBank(false);

        bankData.email = user.email;
        bankData.id = user.sub;
        bankData.name = event.target.acc_name.value;
        bankData.sort_code = event.target.sort_code.value;
        bankData.account_number = event.target.account_number.value;

        if(bankData.sort_code.length != 6){
            setShowBank(true);
            setStatus2("Invalid Sort Code");
            return;
        }

        if(bankData.account_number.length != 8){
            setShowBank(true);
            setStatus2("Invalid Account Number");
            return;
        }

    
        console.log(JSON.stringify(bankData));
    
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken },
          body: JSON.stringify(bankData),
          mode: 'cors'
        };
    
        await fetch(API_URL + "payment/addbank", requestOptions).then(
          response => response.json()
        ).then((result) => {
            if(result.includes('successfully')){
                window.history.replaceState(null, "MyRegPlates", "/my/profile?view=payment&bank=true");
                window.location.reload();
            }else{
                setStatus2(result);
                setShowBank(true);
            }
        }).catch((error) => {
          setStatus(error);
          setShowBank(true);
        });
    }

    async function handleSubmitNick(event) {
        event.preventDefault();
    
        var nickData = {};
        setShowBank(false);

        nickData.nick = event.target.nickname.value;
        nickData.id = user.sub;

        if(nickData.nick.length < 3 || nickData.nick.length > 20){
            setShowBank(true);
            setStatus2("Nickname length invalid!");
            return;
        }
    
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken },
          body: JSON.stringify(nickData),
          mode: 'cors'
        };
    
        await fetch(API_URL + "users/changenick", requestOptions).then(
          response => response.json()
        ).then(async (result) => {
            if(result.includes('successfully')){
                const newAccessToken = await getAccessTokenSilently({ ignoreCache: true });
                window.history.replaceState(null, "MyRegPlates", "/my/profile?view=info&user=true");
                window.location.reload();
            }else{
                setStatus2(result);
                setShowBank(true);
            }
        }).catch((error) => {
          setStatus(error);
          setShowBank(true);
        });
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setState((prev) => ({ ...prev, [name]: value }));
    }
    
    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    async function getUserInfo(){
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
            mode: 'cors'
        };

        const request = await fetch(API_URL + `payment/info/${user.sub}`, requestOptions, {

        }).then((request => request.json()))
          .then((data) => {
           // console.log(data.card.card);
            setData(data)
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })

        const request2 = await fetch(API_URL + `auctions/user/bids/${user.sub}`, requestOptions, {

        }).then((request => request.json()))
          .then((data) => {
            setData2(data)
            setLoading(false) // set Loading to false when you have the data
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })
    }

   
    useEffect(() => {
        getUserInfo(); 
        var view = searchParams.get("view");
        var card = searchParams.get("card");
        var bank = searchParams.get("bank");
        var user = searchParams.get("user");
        if(view == "payment") setSelectedTab(2);
        if(view == "info") setSelectedTab(0);
        if(card == "true") setCardSuccess(true);
        if(bank == "true") setBankSuccess(true);
        if(user == "true") setUserSuccess(true);
        document.title = "Your Profile | MyRegPlates";
    }, [])
    
  
    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>Your Profile</h1>
                </div>

                {loading ? (
                    <Loading></Loading>
                ) : (
                    <>
                        <div className="user-info">
                            <img className="user-image" src={user.picture} alt="User"></img>
                            <div className="user-info-sub">

                                <div className="username-wrapper">
                                    <h2>{user.nickname}</h2>
                                    {data.verified_seller === true ? (
                                        <a data-tooltip-id="verified-tooltip" data-tooltip-content="You are a verified seller on MyRegPlates">
                                            <img className="verified-icon"src={verified} width={20} height={20}/>
                                        </a>
                                    ):(
                                        null
                                    )}
                                </div>
                                <Tooltip id="verified-tooltip" />
                                <p>{user.email}</p>
                                <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="theme-button log-out-button">Log Out</button>
                                {!user.email_verified ? (
                                    <p className="warning-box">⚠️ Your email is unverified </p>
                                ):(
                                    null
                                )}
                                {/*<div className="user-nav">
                                    <Link to="/my/plates" className="user-nav-link">Plates</Link>
                                    <Link to="/my/auctions" className="user-nav-link">Auctions</Link>
                                    <Link to="/my/watchlist" className="user-nav-link">Watchlist</Link>
                                </div>*/}
                            </div>
                        </div>

                        <div className="user-info-more">
                            <div className="user-info-tabs">
                                <button onClick={() => setSelectedTab(0)} className={"user-info-tab " + (selectedTab == 0 ? 'tab-selected' : '')}>
                                    Account Info
                                </button>

                                <button onClick={() => setSelectedTab(1)} className={"user-info-tab activity-tab " + (selectedTab == 1 ? 'tab-selected' : '')}>
                                    Account Activity
                                </button>

                                <button onClick={() => setSelectedTab(2)} className={"user-info-tab " + (selectedTab == 2 ? 'tab-selected' : '')}>
                                    Payment Info
                                </button>
                            </div>

                            <div className="user-info-section">
                                {selectedTab == 2 ? (
                                    <>
                                        <h1 style={{marginBottom: "1rem"}}>Payment Info</h1>
                                        <h2>Payment Card</h2>
                                            
                                        {data.card ? (
                                            <>
                                            
                                            {!cardForm ? (
                                                <p style={{margin: "1rem 0"}}>Card: <b>************{data.card.card.last4}</b><br></br>
                                                Card Expiry: <b>{data.card.card.exp_month}/{data.card.card.exp_year}</b><br></br>
                                                </p>
                                            ):(
                                                null
                                            )}

                                            {cardSuccess ? (
                                                <p className="success-box">Card details added successfully!</p>
                                            ):(
                                                null
                                            )}
                                                
                                            </>
                                        ):(
                                            <>
                                            <p className="warning-box">⚠️ You can't bid on auctions until you provide details for a valid debit/credit card 
                                            <a data-tooltip-id="card-tooltip" data-tooltip-content="You need to provide details for a bank card so that MyRegPlates can charge you for the auctions you win."> <span style={{textDecoration: "underline"}}>Why?</span></a>
                                            </p>
                                            </>
                                        )}
                            
                                        <Tooltip id="card-tooltip" />
                                        {cardForm ? (
                                            <>
                                            <div id='PaymentForm' style={{marginTop: "1rem"}}>      
                                                <Elements stripe={stripePromise} options={options}>
                                                    <SetupForm secret={options.clientSecret} />
                                                </Elements>
                                                {status ?(
                                                    <p className="card-status">{status}</p>
                                                ):(
                                                    null
                                                )}
                                            </div>
                                            </>
                                        ):(
                                            <button onClick={async () => { await getIntent(cardForm); await setCardForm(!cardForm); setCardSuccess(false)}} className="theme-button account-button">
                                                {data.card ? (
                                                    "Change Card Details"
                                                ):(
                                                    "Add New Card"
                                                )}
                                            </button>
                                        )}

                                        <h2 style={{marginTop: "2rem"}}>Bank Account Details</h2>

                                        {data.bank ? (
                                            <>
                                            <p style={{margin: "1rem 0"}}>
                                            Account Holder Name: <b>{data.bank.name}</b><br></br>
                                            Account Sort Code: <b>{data.bank.sort_code}</b><br></br>
                                            Account Number: <b>{data.bank.account_number}</b><br></br>
                                            </p>

                                            {bankSuccess ? (
                                                <p className="success-box">Bank details added successfully!</p>
                                            ):(
                                                null
                                            )}
                                            </>
                                        ):(
                                            <>
                                            <p className="warning-box">⚠️ You can't receive the funds from your completed auctions until you enter your bank account details.
                                                <a data-tooltip-id="bank-tooltip" data-tooltip-content="You need to provide your bank account details so that MyRegPlates can transfer you the value of your sold auction plates."> <span style={{textDecoration: "underline"}}>Why?</span></a>
                                            </p>
                                            </>
                                        )}

                                        <Tooltip id="bank-tooltip" />
                                        {bankForm ? (
                                            <>
                                            <div id="form-section" className="profile-form">
                                                <form className="profile-form-content" onSubmit={handleSubmitBank}>
                                                    <label>
                                                        Account Holder Name
                                                        <input type="text" name="acc_name" className="price-input no-bg"></input>
                                                    </label>

                                                    <label>
                                                        Sort Code
                                                        <input type="text" name="sort_code" className="price-input no-bg"></input>
                                                    </label>

                                                    <label>
                                                        Account Number
                                                        <input type="text" name="account_number" className="price-input no-bg"></input>
                                                    </label>

                                                    {showBank ? (
                                                        <div style={{display: "flex"}}>
                                                            <input type="submit" value={`Submit`} className="theme-button account-button"></input>
                                                            <button onClick={() => setBankForm(false)}className="theme-button account-button ml-i reverse-button" style={{marginTop: "1rem"}}>Close</button>
                                                        </div>
                                                    ):(
                                                        <Loading></Loading>
                                                    )}  
                                                </form>
                                            </div>
                                            {status2 ?(
                                                <p className="card-status">{status2}</p>
                                            ):(
                                                null
                                            )}
                                            </>
                                        ):(
                                            <button onClick={() => {setBankForm(!bankForm); setBankSuccess(false)}} className="theme-button account-button">
                                                {data.bank ? (
                                                    "Change Bank Details"
                                                ):(
                                                    "Add Account Info"
                                                )}
                                            </button>
                                        )}
                                    </>
                                ):(
                                    null
                                )}

                                {selectedTab == 1 ? (
                                    <>
                                    <h1 style={{marginBottom: "1rem"}}>Account Activity</h1>

                                    <h2 style={{marginBottom: "1rem"}}>Bids ({data2.length})</h2>
                                    <div className="bid-history table-overflow-scrollable">
                                        {data2.length > 0 ? (
                                            <>
                                                <table className="bid-history-table">
                                                    <tbody>
                                                        {data2.slice().reverse().map(bid_item => (
                                                            <Link to={`/plates/${bid_item.url}`} className="bid-history-item" >
                                                                <tr className="bid-history-item inner-item" key={bid_item._id}>
                                                                    <td><b>Plate: </b> {bid_item.url}</td>
                                                                    <td><b>Amount: </b> £{bid_item.amount.toLocaleString()}</td>
                                                                    <td><b>Date: </b> {moment(bid_item.date).format('DD MMMM YYYY HH:mm:ss')}</td>
                                                                </tr>
                                                            </Link>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <p style={{ padding: "1rem", color: "gray", fontWeight: "bold" }}>You haven't placed any bids yet.</p>
                                        )}
                                    </div>
                                    </>
                                ):(
                                    null
                                )}

                                {selectedTab == 0 ? (
                                    <>
                                    <h1>Account Info</h1>

                                    <h3 style={{marginTop: "1rem"}}>Nickname</h3>

                                    <div id="form-section" className="profile-form" style={{background: "none", paddingLeft: "0", paddingTop: "0"}}>
                                        <form className="profile-form-content" onSubmit={handleSubmitNick}>
                                            <input type="text" name="nickname" className="price-input no-bg" defaultValue={user.nickname}></input>

                                            {showBank ? (
                                                <input type="submit" value={`Update`} className="theme-button account-button"></input>
                                            ):(
                                                <Loading></Loading>
                                            )}  
                                        </form>
                                    </div>


                                    <div id="form-section" className="profile-form" style={{background: "none", paddingLeft: "0", paddingTop: "0"}}>
                                        {showPass ? (
                                            <form className="profile-form-content" onSubmit={handleSubmitPass}>
                                                {showCard ? (
                                                    <input type="submit" value={`Reset Password`} className="theme-button account-button"></input>
                                                ):(
                                                    <Loading></Loading>
                                                )}  
                                            </form>
                                        ):(
                                            null
                                        )}
                                    </div>

                                    
                                    <div id="form-section" className="profile-form" style={{background: "none", paddingLeft: "0", paddingTop: "0"}}>
                                        <form className="profile-form-content" onSubmit={handleSubmitMail}>
                                            <div style={{display: "flex"}}>
                                                <input type="checkbox" onClick={() => setShowMail(true)}defaultChecked={data.allow_email} className="tos-input" style={{width: "20px", height: "20px"}} name="tosinput"></input>
                                                <label for="tosinput" className="tos-label" style={{fontSize: "1rem"}}><span>Get notifications about your auctions through email</span></label>
                                            </div>

                                            {showMail ? (
                                                <input type="submit" value={`Update`} className="theme-button account-button"></input>
                                            ):(
                                                null
                                            )}  
                                        </form>
                                    </div>

                                    {userSuccess ? (
                                        <p className="success-box">Nickname changed successfully!</p>
                                    ):(
                                        null
                                    )}

                                    {mailSuccess ? (
                                        <p className="success-box">Email preferences updated successfully!</p>
                                    ):(
                                        null
                                    )}

                                    {status2 ?(
                                        <p className="success-box">{status2}</p>
                                    ):(
                                        null
                                    )}
                                    </>
                                ):(
                                    null
                                )}
                                    
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Profile