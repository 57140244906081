import React, { useEffect, useState } from 'react'
import { useParams } from "react-router";
import { API_URL, WS_URL, API_URL_ADMIN } from './common';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useAuth0, User } from "@auth0/auth0-react";
import './plate.css';
import './Admin.css'
import io from 'socket.io-client';
import saveIcon from './res/save.png'
import shareIcon from './res/share.png'
import eyeIcon from './res/eye.svg'
import ImageViewer from 'react-simple-image-viewer';
import { FileUploader } from "react-drag-drop-files";
import Loading from './Loading';
import { InView, useInView } from 'react-intersection-observer';
import { Tooltip } from 'react-tooltip';
import verified from './res/verified.png'

function PlateAdmin() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [data2, setData2] = useState([]);
    const [apiStatus, setStatus] = useState();
    const [declineStatus, setDeclineStatus] = useState();
    const [highBid, setHighBid] = useState();
    const [file, setFile] = useState(null);
    const [viewDecline, setViewDecline] = useState(false);
    const [viewVerify, setViewVerify] = useState(false);
    const [viewUpload, setViewUpload] = useState(false);
    const [viewBid, setViewBid] = useState(false);
    const { isAuthenticated, user, getAccessTokenSilently} = useAuth0();
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imgs, setImgs] = useState([]);
    const [ref, inView] = useInView({threshold: 0.5});
    let { id } = useParams();
    const fileTypes = ["JPG"];

    const handleChange = (file) => {
        setFile(file);
        console.log(file.name);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    async function insertBid(event){
        event.preventDefault();

        var plateData = {};

        plateData.plate = data;

        console.log(JSON.stringify(plateData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(plateData),
            mode: 'cors'
        };

        await fetch(API_URL_ADMIN + "auctions/insertbid", requestOptions).then(
            response => response.json()
            ).then((result) => {
                setDeclineStatus(result);
            }).catch((error) => {
                setDeclineStatus(error);
        });
    }

    async function verifyAuction(event){
        event.preventDefault();

        var plateData = {};

        plateData.plate = data;

        console.log(JSON.stringify(plateData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(plateData),
            mode: 'cors'
        };

        await fetch(API_URL_ADMIN + "auctions/verify", requestOptions).then(
            response => response.json()
            ).then((result) => {
                setDeclineStatus(result);
            }).catch((error) => {
                setDeclineStatus(error);
        });
    }

    async function handleSubmit2(event){
        event.preventDefault();

        var certData = {};
        const base64 = await convertBase64(file)
        const parts = base64.split(",");

        certData.file = parts[1];
        certData.file_name = data.cert_id;

        console.log(JSON.stringify(certData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(certData),
            mode: 'cors'
        };

        await fetch(API_URL_ADMIN + "auctions/uploadcert", requestOptions).then(
            response => response.json()
            ).then((result) => {
                setDeclineStatus(result);
            }).catch((error) => {
                setDeclineStatus(error);
        });
    }

    async function handleSubmit(event){
        event.preventDefault();

        var declineData = {};

        declineData.reason = event.target.reason.value;
        declineData.plate = data;

        console.log(JSON.stringify(declineData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(declineData),
            mode: 'cors'
        };

        await fetch(API_URL_ADMIN + "auctions/decline", requestOptions).then(
                response => response.json()
            ).then((result) => {
                setDeclineStatus(result);
            }).catch((error) => {
                setDeclineStatus(error);
        });
    }

    async function addToWatchlist(){
        var _data = {};

        _data.for = data._id;
        _data.by = user.sub;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(_data),
            mode: 'cors'
        };

        await fetch(API_URL + "auctions/addwatch", requestOptions).then(
            response => response.json()
        ).then((result) => {
            setStatus(result);
        }).catch((error) => {
            console.log(error);
            //setStatus(error);
        });
    }

    async function buyoutAuction(event){
        event.preventDefault();

        var bidData = {};

        bidData.for = data._id;
        bidData.by = user.sub;

        console.log(JSON.stringify(bidData));

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bidData),
            mode: 'cors'
        };

        await fetch(API_URL + "auctions/buyout", requestOptions).then(
            response => response.json()
        ).then((result) => {
            setStatus(result);
        }).catch((error) => {
            console.log(error);
            //setStatus(error);
        });
    }

    async function endAuction(event) {
        event.preventDefault();
        const request = await fetch(API_URL + `auctions/end/${data._id}`, {

        }).then((request => request.json()))
            .then((data) => {
                setStatus(data)
                console.log(data);
            }).catch(err => console.log(err))
    }

    async function handleBid(event) {
        event.preventDefault();

        var bidData = {};

        if (Number(event.target.bid.value) <= data.highest_bid) {
            setStatus("Your bid must be higher than the current highest bid.");
            return;
        }

        bidData.for = data._id;
        bidData.by = user.sub;
        bidData.amount = Number(event.target.bid.value);
        bidData.name = user.nickname;
        bidData.room = data.url;

        console.log(JSON.stringify(bidData));

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bidData),
            mode: 'cors'
        };

        await fetch(API_URL + "auctions/bid", requestOptions).then(
            response => response.json()
        ).then((result) => {
            setStatus(result);
        }).catch((error) => {
            console.log(error);
            //setStatus(error);
        });
    }

    async function getPlate() {
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
        };

        const request = await fetch(API_URL_ADMIN + `auctions/${id}`, requestOptions, {

        }).then((request => request.json()))
            .then((data) => {
                setData(data)
                //setLoading(false)
                console.log(data);
                setImgs(imgs => [...imgs,"https://myregplates.s3.eu-west-2.amazonaws.com/" + data.cert_id] );
                setHighBid(data.highest_bid);
            }).catch(err => console.log(err))
    }

    async function getBids() {
        const request = await fetch(API_URL + `auctions/get/bids/${data._id}`, {

        }).then((request => request.json()))
        .then((data2) => {
            setData2(data2)
            setLoading(false)
            console.log(data2);
            connectToRoom();
        }).catch(err => console.log(err))
    }

    async function connectToRoom(){
        const socket = io(WS_URL);
        socket.connect();
        socket.emit('joinroom', data.url);
        socket.on('bid', function (data) {
            setData2(oldArray => [...oldArray,data] );
            setHighBid(data.amount);
        });

        socket.on('plate', function (data) {
            setData(oldArray => [data,...oldArray] );
        });
    }

    useEffect(() => {
        getPlate();
    }, []);

    useEffect(() => {
        getBids();
    }, [data]);


    return (
        <div id="plate-view-wrapper">
            <div id="plate-view">
                {loading ? (
                    <Loading></Loading>
                ) : (
                    data.name === '500_UNAUTHORIZED' ? (
                        <p>ADMIN: this plate doesn't exist</p>
                    ):(
                    <>
                    <div class="plate-view-sub pv-top">
                        {!inView ? ( 
                            <div className="plate-mobile-header">
                                <div className="small-plate">
                                    {data.name}
                                </div>
                            </div>
                        ):(
                            null
                        )}
                        <div class="plate-container-left">
                           

                            <div class="sub-left">
                                <div id="main-plate" ref={ref}>
                                    {data.name}
                                </div>
                                
                                {data.status !== 'unverified' ? (
                                    <>
                                    <p style={{textAlign: "center", paddingTop: "1rem" , color: "#aaaaaa"}}>This plate is verified by MyRegPlates</p>

                                    {data.description?.length > 0 ? (
                                        <div class="plate-desc-wrapper">
                                            <h3>Plate Description:</h3>
                                            <p style={{ marginTop: '1rem'}}>{data.description}</p>
                                        </div>
                                    ):(
                                        null
                                    )}

                                    </>
                                ):(
                                    null
                                )}
                            </div>

                            <div class="sub-right">   
                                <div class="plate-container-left bid-section">
                                    <div className="plate-bid-section-header">
                                        <h2 className="info-header">Auction Info</h2>
                                        <div className="action-icon-container">
                                                <button className="action-icon" onClick={() => addToWatchlist()}>
                                                    {/*<p style={{marginRight: "0.5rem", fontWeight: "bold", color: "white", opacity: "0.5"}}>({data.watched_by_count})</p>*/}
                                                    <img src={eyeIcon} alt="Add to Watchlist" width="32" height="32"></img>
                                                </button>
                                        </div>
                                    </div>

                                    <div class="plate-info">
                                        <p className="info-text"><b>Plate: </b> <br></br>{data.name?.toUpperCase()}</p>
                                        
                                        <div className="info-text-wrapper">
                                            <p className="info-text info-text-wrapped"><b>Seller:</b></p>
                                            <div className="icon-text-align">
                                                <p>{data.created_by_nick}</p>
                                                {data.verified_seller ? (
                                                    <a data-tooltip-id="verified-tooltip" data-tooltip-content="This user has previously sold a plate with MyRegPlates">
                                                        <img className="verified-icon"src={verified} width={16} height={16}/>
                                                    </a>
                                                ):(
                                                    null
                                                )}           
                                            </div>
                                        </div>

                                        <Tooltip id="verified-tooltip" />
                                        
                                        <p className="info-text"><b>Auction started: </b> <br></br>
                                            {data.status.includes('unverified') ? (
                                                <p>Pending</p>
                                            ):(
                                                <>
                                                {moment(data.created_on).format('DD MMMM YYYY')}
                                                </>
                                            )}
                                        </p>

                            
                                    </div>

                                    {!data.status.includes('unverified') ? (
                                        <div id="auction-info">
                                            <div className='info-container'>
                                                <p>Starts At: <span className="info-important">£{data.start_price.toLocaleString()}</span> </p>  
                                                {data.buyout_price > 0 ? (
                                                    <span className="info-sub">(Buy Now: £{data.buyout_price.toLocaleString()})</span>
                                                ):(
                                                    null
                                                )}
                                            </div>

                                            <div className='info-container'>
                                                <p>Top Bid: <span className="info-important highest-bid" key={Math.random()}>£{highBid.toLocaleString()}</span> </p>  
                                                {data.minimum_price > 0 ? (
                                                    <span className="info-sub">(Min. price: £{data.minimum_price.toLocaleString()})</span>
                                                ):(
                                                    null
                                                )}
                                            </div>

                                            {!data.status.includes('finished') ? (
                                                <div className='info-container'>
                                                    <p>Time Left: <span className="info-important"><Countdown date={Date.parse(`${data.expires_on}`)} /></span></p>
                                                    <span className="info-sub">(Ends: {moment(data.expires_on).format('DD MMMM YYYY HH:mm')})</span>
                                                </div>
                                            ):(
                                                null
                                            )}
                                                
                                        </div>
                                    ):(
                                        null
                                    )}

                                  

                                    <div className="bid-table-header">
                                        <h3 className="bid-count" key={Math.random()}>Bids ({data2.length})</h3>
                                    </div>

                                    <div className="bid-history table-overflow-scrollable">
                                        
                                        {data2.length > 0 ? (
                                            <>
                                                <table className="bid-history-table">
                                                    <tbody>
                                                        {data2.slice().reverse().map(bid_item => (
                                                            <tr className="bid-history-item" key={bid_item._id}>
                                                                <td><b>By: </b> {bid_item.by_nick}</td>
                                                                <td><b>Amount: </b> £{bid_item.amount.toLocaleString()}</td>
                                                                <td><b>Date: </b> {moment(bid_item.date).format('DD MMMM YYYY HH:mm:ss')}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <p style={{ padding: "1rem" }}>There are no bids for this auction yet.</p>
                                        )}
                                    </div>

                                   
                                        <div class="plate-gallery-wrapper">
                                            <div className="bid-table-header">     
                                                <h3 className="bid-count" >Plate Certificate:</h3>
                                            </div>
                                            
                                            <img className="gallery-img"
                                                src={ "https://myregplates.s3.eu-west-2.amazonaws.com/" + data.cert_id }
                                                onClick={ () => setIsViewerOpen(true) }
                                                width="200"
                                                style={{ marginTop: '1rem', maxWidth: "200px", maxHeight: "200px" }}
                                                alt="certificate"
                                            />
                                            {isViewerOpen && (
                                                <ImageViewer
                                                backgroundStyle={{zIndex: "500"}}
                                                src={ imgs }
                                                currentIndex={ 0 }
                                                disableScroll={ false }
                                                closeOnClickOutside={ true }
                                                onClose={ () => setIsViewerOpen(false) }
                                                />
                                            )}
                                        </div>
                                   
                                    <div className="admin-section">
                        <h2>Admin</h2>
                        <p>
                            Status: {data.status} <br/>
                            Sold To: {data.sold_to} <br/>
                            Created By: {data.created_by} <br/><br/>
                        </p>
                        
                        <p>
                            <b>Cert Id:</b> {data.semantic_id}
                        </p>

                        <p>
                            <b>Expects:</b> {data.cert_id}
                        </p>

                        <button onClick={() => setViewVerify(!viewVerify)} className="theme-button account-button">Verify Plate</button>
                        <button onClick={() => setViewDecline(!viewDecline)} className="theme-button account-button">Decline Plate</button>
                        <button onClick={() => setViewUpload(!viewUpload)} className="theme-button account-button">Upload Certificate</button>
                        <button onClick={() => setViewBid(!viewBid)} className="theme-button account-button">Insert Bid</button>

                        {viewBid ?(
                            <>
                            <h5>Insert a ghost bid for this plate</h5>
                            <h5 style={{color: "red"}}>You cannot insert a bid higher than the reserve price.</h5>
                            <form onSubmit={insertBid} style={{display: "flex", flexDirection: "column"}}>
                                <label style={{display: "flex", gap: "0.5rem"}}>
                                    <label for="nameinput" className="tos-label" style={{fontSize: "1rem"}}>Username</label>
                                    <input type="text" name="nameinput" className="price-input no-bg"></input>
                                </label>

                                <label style={{display: "flex", gap: "0.5rem"}}>
                                    <label for="valueinput" className="tos-label" style={{fontSize: "1rem"}}>Bid Value</label>
                                    <input type="text" name="valueinput" className="price-input no-bg"></input>
                                </label>

                                <input className="submit-input" type="submit"></input>
                            </form>
                            </>
                        ):(
                            null
                        )}

                        {viewVerify ?(
                            <>
                            <h5>Are you sure you want to verify this plate and begin the auction?</h5>
                            <h5 style={{color: "red"}}>This action cannot be undone.</h5>
                            <form onSubmit={verifyAuction}>
                                <input className="submit-input" type="submit"></input>
                            </form>
                            </>
                        ):(
                            null
                        )}

                        {viewDecline ? (
                            <>
                            <h3>Reason for decline: </h3>
                            <form onSubmit={handleSubmit}>
                                <textarea name="reason" type="text"></textarea>
                                <input className="submit-input" type="submit"></input>
                            </form>
                            </>
                        ):(
                            null
                        )}

                        {viewUpload ? (
                            <>
                            <form onSubmit={handleSubmit2}>
                                <label>
                                    Public Certificate
                                    <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes="drop-zone" label="Upload or drop a file here" />
                                </label>

                                <input type="submit" name="submit" className="submit-input" text="Submit"></input>
                            </form>
                            </>
                        ):(
                            null
                        )}

                        {declineStatus ? (
                            <p style={{ paddingBottom: "1rem", textAlign: "center", fontWeight: "bold", backgroundColor: "white", border: "3px solid black" }}>{declineStatus}</p>
                        ) : (
                             null
                        )}

                    </div>

                    <p>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                    </p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    </>
                    )
                    
                )}
            </div>
        </div>
                    

    )
}

export default PlateAdmin