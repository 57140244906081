import React from 'react'
import loading from './res/loading.svg'

function Loading() {
  return (
    <div>
        <img src={loading} width="32" height="32" alt="loading"></img>
    </div>
  )
}

export default Loading