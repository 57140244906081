import React, { useEffect, useState }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import './App.css';
import './marquee.css';
import './About.css';
import Loading from './Loading';
import { API_URL } from './common';
import { useNavigate} from "react-router-dom";
import Popup from 'reactjs-popup';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'
import { Link } from 'react-router-dom';

function Valuation() {
    const { user, isAuthenticated } = useAuth0();
    const [apiStatus, setStatus] = useState();
    const navigate = useNavigate();
    const [showSubmit, setShowSubmit] = useState(true);
    const [relist, setRelist] = useState(false);
    const [formStatus, setFormStatus] = useState(null);
    const [plate, setPlateValue] = useState('');

    const closeModal = () => setRelist(false);
    
    useEffect(() => {
        document.title = " MyRegPlates | Get a valuation!";
        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', 'Get a free price valuation for your registration plate!')

        const el2 = document.querySelector("link[rel='canonical']");
        el2.setAttribute('href', 'https://myregplates.com/valuation')
    }, [])

    function setPlate(event){
      setPlateValue(event.target.value);
    }

    async function handleSubmit(event) {
        //event.stopImmediatePropagation();
        event.preventDefault();
        
        setShowSubmit(false);

        var plateData = {};

        if(plate.length < 2) {

          if(plate.length == 0) {
            setFormStatus('Invalid plate number');
          }else{
            setFormStatus(plate + ' is not a valid plate!');
          }

          setShowSubmit(true);
          return;
        }

        if(isAuthenticated) {
          plateData.name = event.target.valin.value;
          plateData.uid = user.sub;
          plateData.email = "NULL";
          plateData.fullname = "";
          plateData.platetype = "";
          plateData.name = plateData.name.toUpperCase();
        }else{
          plateData.name = plate.toUpperCase();
          plateData.email = event.target.emailinput.value;
          plateData.fullname = event.target.nameinput.value;
          plateData.platetype = event.target.status.value;
          plateData.uid = "NO_USER";

          if(plateData.email.trim() === '' || plateData.fullname.trim() === '') {
            setFormStatus('Please enter all of the required information.');
            setShowSubmit(true);
            return;
          }

        }

        console.log(plateData);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(plateData),
            mode: 'cors'
        };
    
        await fetch(API_URL + "valuations/create", requestOptions).then(
          response => response.json()
        ).then((result) => {
          //setStatus(result);
          if(result.includes('successfully')){
            navigate(`/valuation/submitted`)
          }else{
            setFormStatus(result);
            closeModal();
          }
        }).catch((error) => {
          setFormStatus(error);
          closeModal();
        });
    
        setShowSubmit(true);
    }

    return (
        <div>
           <div id="hero" className="about-hero valuation-hero">
                <div id="hero-text" className="about-hero-text notfound valuation-wrapper">
                    <p className="valuation-text" style={{textAlign: "center"}}>Get a <span id="premier">free</span> price valuation for your registration plate!</p>
                    <p className="valuation-sub" style={{textAlign: "center"}}>Simply enter your plate number and we will email you shortly with a price value for your plate!</p>
                    <form className="valuation-form" onSubmit={handleSubmit}>  
                        <>
                        <input placeholder="PLATE" maxLength="10" type="text" name="valin" className="plate-input" id="valuation-input" onChange={(e) => setPlate(e)}></input>
                        {showSubmit ? (
                            isAuthenticated ? (
                              <input style={{marginTop: "1rem"}} type="submit" name="submit" className="confirm-button" text="Submit"></input>
                            ):(
                              null
                            )
                        ):(<Loading/>)}
                        {formStatus ? (
                          <p style={{color: "black", marginTop: "1rem", backgroundColor: "white", padding: "1rem", border: "2px solid black", fontWeight: "bold"}}>⚠️ {formStatus}</p>
                        ):(
                          null
                        )}

                        </>
                    </form> 
                        {showSubmit ? (
                            isAuthenticated ? (
                              null
                            ):(
                              <>
                              <button type="button" className='confirm-button' style={{margin: 0}} onClick={() => setRelist(true)}>Submit</button>
                              <Popup open={relist} closeOnDocumentClick={false} onClose={closeModal}>
                                  <div className="bid-popup-container">
                                      <div style={{display: "flex", justifyContent: "space-between"}}>
                                          <h2 style={{paddingBottom: "1rem"}}>One more step...</h2>
                                          <button type="button" onClick={() => {setRelist(false)}} className="close-popup">×</button>
                                      </div>
                                      <p style={{padding: "1rem 0", color: "gray", paddingBottom: "0"}}>
                                        We need some more information before we can provide a valuation for your plate</p>

                                      <form onSubmit={handleSubmit}>
                                        <div class="valuation-form-inner">
                                            <label>
                                              <label for="nameinput" className="tos-label" style={{fontSize: "1rem"}}>Your Full Name</label>
                                              <input type="text" name="nameinput" className="price-input no-bg"></input>
                                            </label>

                                            <label>
                                              <label for="emailinput" className="tos-label" style={{fontSize: "1rem"}}>Your Email</label>
                                              <input type="text" name="emailinput" className="price-input no-bg"></input>
                                            </label>

                                            <label style={{paddingTop: "0.5rem"}}>
                                              <span>Plate Status</span>
                                              <div className='duration-input-wrapper' style={{ width: "100%"}}>
                                                <select style={{padding: "0.5rem", width: "100%"}} name="status" className="duration-input">
                                                  <option value="1">On Retention</option>
                                                  <option value="2">Assigned to Vehicle</option>
                                                  {/*<option style={{color: "red", fontWeight: "bold"}} value="3">5 Minutes (DEV ONLY)</option>*/}
                                                </select>
                                                <div className='duration-input-arrow'>▾</div>
                                              </div>
                                            </label>
                                            {showSubmit ? (
                                              <input className='confirm-button' style={{margin: 0, marginTop: "1.5rem"}} type="submit" name="submit" value="Submit"></input>
                                            ):(
                                              <Loading></Loading>
                                            )}

                                            {formStatus ? (
                                              <p style={{padding: "1rem", color: "red", paddingLeft: "0", paddingBottom: "0"}}>{formStatus}</p>
                                            ):(
                                              null
                                            )}
                                        </div>
                                      </form>
                                      <p style={{padding: "1rem 0", color: "gray", paddingBottom: "0", maxWidth: "600px", paddingTop: "1.5rem"}}>
                                      Want to skip this step in the future? <a rel="nofollow" href={`https://auth.myregplates.com/authorize?response_type=code&client_id=jz2Srv6g7oNYPiWJkoI9SoNcnsUzIpPc&redirect_uri=${window.location.origin}&scope=SCOPE&state=STATE&screen_hint=signup`} style={{fontWeight: "bold", color: "black"}}>Sign up or login </a> 
                                      to join our community and gain access to our auctions, valuation service and more!</p>
                                  </div>
                              </Popup>
                              </>
                            )
                        ):(
                            <Loading></Loading>
                        )}
                </div>
            </div>

           
        </div>
    )
}

export default Valuation