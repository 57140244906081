import React, { useEffect, useState } from 'react'
import { API_URL_ADMIN } from './common';
import './plates.css'
import Table from './Table';
import { useAuth0, User } from "@auth0/auth0-react";
import AdminNav from './AdminNav';

function UsersAdmin() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const {isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    
    async function getPlates() {
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
            mode: 'cors'
        };

        const request = await fetch(API_URL_ADMIN + `users`, requestOptions, {

        }).then((request => request.json()))
        .then((data) => {
            setData(data)
            setLoading(false) // set Loading to false when you have the data
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })
    }

    useEffect(() => {
        getPlates();
    }, []);

    return (
        <div id="plate-wrapper">
            <div id="plate-section" className="flat-table-section">
                <div id="header-section">
                    <h1>Admin (Users)</h1>
                    <AdminNav/>
                </div>

                {loading ? (
                    <p>Loading... (DEV NOTE: THIS TAKES UP TO A MINUTE ON THE TEST SERVER)</p>
                ) : (
                    <div className="flat-table">
                        <div className="flat-table-row header-row">
                            <p className="flat-table-row-item short-item">NICKNAME</p>
                            <p className="flat-table-row-item">ID</p>
                            <p className="flat-table-row-item">EMAIL</p>

                            <p className="flat-table-row-item">STRIPE ID</p>

                            <p className="flat-table-row-item short-item">DENY EMAIL?</p>
                            <p className="flat-table-row-item xshort-item">VER</p>
                            <p className="flat-table-row-item xshort-item">DLQ</p>

                            <p className="flat-table-row-item xshort-item">BANK</p>
                            <p className="flat-table-row-item xshort-item">CARD</p>
                        </div>
                        {data.reverse().map(user => (
                            <div className="flat-table-row">
                                
                             
                                <p className="flat-table-row-item short-item">{user?.nick ? user.nick : "AUTH0"}</p>
                                <p className="flat-table-row-item">{user?.user}</p>
                                <p className="flat-table-row-item">{user?.email ? user.email : "NO_EMAIL"}</p>

                                <p className="flat-table-row-item">{user?.stripe_id}</p>

                                <p className="flat-table-row-item short-item">{user.allow_email ? "" : "DENY_EMAIL"}</p>
                                <p className="flat-table-row-item xshort-item">{user?.verified_seller ? "✔️" : ""}</p>
                                <p className="flat-table-row-item xshort-item">{user?.delinquent ? "⚠️" : ""}</p>

                                <p className="flat-table-row-item xshort-item">{user?.bank ? "💰" : ""}</p>
                                <p className="flat-table-row-item xshort-item">{user?.card ? "💳" : ""}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default UsersAdmin