import React, { useEffect, useState } from 'react'
import './plates.css'

function Legal() {
    useEffect(() => {
        document.title = "Legal | MyRegPlates";
    }, [])

    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>Legal</h1>
                </div>

                <p style={{lineHeight: "1.5rem"}}>
                <h2>Terms of Use:</h2>
                <br/>
                By using the MyRegPlates website, you agree to comply with and be bound by the following terms and conditions of use:
                <br/><br/>

                <ul>
                <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.</li>
                <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                <li>By bidding on an auction, you give MyRegPlates the permission to assign the number plate to your chosen vehicle on your behalf, should you win the auction.</li>
                <li>In the event of a successful transaction where an auction is won and the reserve price is met, sellers give MyRegPlates the right to assign the sold number plate to the buyers vehicle.</li>
                <li>For the duration of your live auctions, you may not take actions which would affect their integrity, including (but not limited to): <br/>
                - Selling the plate currently on auction to a private buyer<br/>
                - Listing the plate currently on auction for sale with another website or service <br/>
                - Assigning the plate currently on auction to a vehicle, thus invalidating the certificate <br/>
                - Taking any other action which may invalidate the retention certificate that the plate is held on<br/>
                Failure to comply with the above will result in the termination of your account.
                </li>
                <br/>
                <li>
                In the event that an auction finishes successfully, because a buyer is found and the reserve price is met, but the payment method used by the buyer declines, the buyer has 7 days to retry the transaction, either with the same payment method, or with an alternative payment card (which can be added by visiting the Payment Info tab in the Profile page). Failure to do so will result in the buyer losing the auction, while still being liable to pay MyRegPlates' transaction fees totalling 10% of the final auction price.
                </li>
                <br/>
                <b>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</b>
                <br/>
                <li>From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                <br/>
                Your use of this website and any dispute arising out of such use of the website is subject to the laws of United Kingdom.
                </ul>
                <br/>
                <h2>Privacy Policy:</h2>
                <br/>
                MyRegPlates is committed to protecting your privacy. We collect and use your personal information only as outlined in our Privacy Policy, which can be found on a separate page on this website.
                <br/><br/>
                <h2>Copyright Notice:</h2>
                <br/>
                This website and its content are copyright of MyRegPlates. All rights reserved. Any redistribution or reproduction of part or all of the contents in any form is prohibited.
                If you have any questions or concerns about our legal policies, please contact us using the information provided on the Contact Us page.
                </p>
                
            </div>
        </div>
    )
}

export default Legal