import React, { useEffect }  from 'react'
import './App.css';
import './marquee.css';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth0, User } from "@auth0/auth0-react";

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'
import logo from './res/logo-white.svg'
import stars from './res/stars.svg'



function Landing() {
    const {isAuthenticated, user, logout, getAccessTokenSilently } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        var plates = [
        'BBA 51T',
        'H23 NNA',
        '7 AHM',
        '5 MHD',
        'AD21 AMM',
        'A24 AMX',
        'A24 BAS',
        'N9 RTX',
        'SVJ 1S',
        'X K65HEM',
        'RAM130S',
        'IBZ 990',
        'OM11 BAD',
        'B11 ASU',
        'REM 85H',
        'HH23 MZA',
        'XH04 MZA',
        'EAZ 1786',
        'WDD 6',
        'TH5 6OAT',
        '36 JAM',
        '25 DBS',
        'V8 SLR',
        'EM62 MAD',
        'BTC 10V',
        'U AZ112',
        'G4P OU',
        'RAZ 7771',
        'A7 TXB',
        'P44 YYD',
        'X U22ZYY',
        'HR15 RAN',
        'D113 SEL',
        'V4 DDE',
        'AA22 HER',
        'Y33 MHR',
        'HF54 AAA',
        'HA53XB X',
        'SAK 18X',
        'A21MS X',
        'OH 51HAY',
        'AD21 AMM',
        'ADA 9M',
        'H14 FSA'
        ]
        
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }
        
        var quotes = document.getElementsByClassName("text-white");
        for(var i = 0; i < quotes.length; i++){
            quotes[i].innerHTML = plates[getRandomInt(plates.length - 1)];
        }
        
        document.title = "MyRegPlates | The UK's Premier Registration Plate Marketplace";

        async function getToken(){
            await getAccessTokenSilently({ ignoreCache: true });
            //window.history.replaceState(null, "MyRegPlates", "/");
            //window.location.reload();
        }

        var user = searchParams.get("email_verified");
        if(user === 'true'){
            getToken();
        }
    }, [])
    
    return (
        <div>
            <div id="hero">
                <div id="hero-text">
                    <p className="hero-large">The UK's <span id="premier">premier</span> number plate <br/> marketplace</p>
                    <p className="hero-sub">Whether you're selling or buying, MyRegPlates is the perfect registration plate community</p>
                    <Link to="/plates">
                        <div id="action-button">
                            <p>Browse Plates <span className="arrow">❯</span></p>
                        </div>
                    </Link>
                </div>

                <div id="separator-wrapper">
                    <div id="separator"></div>
                </div>


                <div className="marquee-wrapper">
                    <div className="container">
                        <div className="marquee-block">
                            <div className="marquee-inner to-left">
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                </span>
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="marquee-block">
                            <div className="marquee-inner to-right">
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>

                                </span>
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="marquee-block">
                            <div className="marquee-inner to-left">
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                </span>
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="marquee-block">
                            <div className="marquee-inner to-right">
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>

                                </span>
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="marquee-block">
                            <div className="marquee-inner to-left">
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>

                                </span>
                                <span>
                                    <div className="marquee-item">
                                        <p className="text-white">8</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">7</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">6</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">5</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">4</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">3</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">2</p>
                                    </div>
                                    <div className="marquee-item">
                                        <p className="text-white">1</p>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div id="about">
                <div className="bottom-separator">
                    
                </div>
                <div id="about-content">
                    <p className="section-title section-title-top">A marketplace you can trust</p>
                    <p id="about-content-text">
                        MyRegPlates connects buyers with sellers from across the country, making it easy to find the perfect plate for your car. Whether you're looking to part ways with a plate you no longer use or are a collector looking to sell a rare plate, our platform makes it easy to list and sell your plates to interested buyers.
                    </p>
                    <div style={{display: "flex", alignItems: "center"}}> 
                    <Link to="/about">
                        <div id="action-button-2">
                            <p>Learn More <span className="arrow">❯</span></p>
                        </div>
                    </Link>
                    <Link to="/faq">
                        <p style={{fontWeight: "700", marginLeft: "1.5rem", color: "rgb(1, 60, 119)", fontSize: "1rem"}}>Read our FAQ</p>
                    </Link>
                    </div>
                </div>
            </div>

            {/*
            <div id="about-rating">
    
                <div id="about-content">
                    <h1>A marketplace you can trust</h1>
                    <p id="about-content-text">
                        MyRegPlates is trusted by hundreds of registration plate enthusiasts. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec feugiat convallis hendrerit. Vestibulum sem diam, maximus at tempor in, efficitur id velit. Donec consectetur et elit quis consectetur. Integer a elementum risus.
                    </p>
                    <div style={{display: "flex", alignItems: "center"}}> 
                        <a className="tp-out" href="https://uk.trustpilot.com/review/myregplates.com">
                            <div className="ratings-component">
                                <div className="rating-text">Excellent</div>
                                <img src={stars} height={30} />
                                <div class="rating-text-2"><strong>4.6</strong> out of 5 based on <strong>250 reviews</strong></div>
                                <img src={logo} height={20}/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            */}
            <div id="about2-content-bottom">
                <div className="section-title section-title-white">Everything you need in one place</div>

                <div id="about2-content-container">
                    <div className="about2-content-panel">
                        <div className="content-panel-header">
                            <img className="link-icon" src={person} alt="Buy"/>
                            <div className="card-title">Buy</div>
                        </div>
                        <p>Our auction process is simple and easy to use. Browse through our listings, place a bid on the plate you want, and wait for the auction to close. If you're the highest bidder, the plate is yours! Or if you cannot wait, simply click “Buy now”. </p>
                    </div>

                    <div className="about2-content-panel">
                        <div className="content-panel-header">
                            <img className="link-icon" src={doc} alt="Sell"/>
                            <div className="card-title">Sell</div>
                        </div>
                        <p>Our platform allows sellers to set a reserve price, ensuring that they receive a fair price for their plate. You can also set the duration of the auction, so you can customise each listing to your liking!</p>
                    </div>

                    <div className="about2-content-panel">
                        <div className="content-panel-header">
                            <img className="link-icon" src={home} alt="Manage"/>
                            <div className="card-title">Manage</div>
                        </div>
                        <p>Our website is secure and user-friendly, making it easy to buy and sell plates with peace of mind. View documents, manage your bids and auctions with our all-in-one user dashboard.</p>
                    </div>
                </div>
            </div>

            <div id="sign-up-wrapper">
                <div id="sign-up">
                    <p className="section-title">Ready to get started?</p>
                    <p id="about-content-text">Sign up to MyRegPlates now and start browsing our listings and find the perfect personalised number plate for your vehicle today! </p>

                    <div id="sign-up-pane-container">
                        <a rel="nofollow" href={`https://auth.myregplates.com/authorize?response_type=code&client_id=jz2Srv6g7oNYPiWJkoI9SoNcnsUzIpPc&redirect_uri=${window.location.origin}&scope=SCOPE&state=STATE&screen_hint=signup`}>
                            <div id="action-button-2">
                                <p>Sign Up <span className="arrow">❯</span></p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing