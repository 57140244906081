import React, { useEffect }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import './App.css';
import './marquee.css';
import './About.css';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'

function SignIn() {
    const { loginWithRedirect } = useAuth0();
      
    useEffect(() => {
        document.title = "Sign In | MyRegPlates";
    }, [])


    return (
        <div>
           <div id="hero" className="about-hero">
                <div id="hero-text" className="about-hero-text">
                    <h1>Log In or Sign Up To Continue</h1>
                    <h2>Create an account or log in to an existing one to place bids and list your registration plates for auction!</h2>
                    <div style={{display: "flex", alignItems: "center"}}>   
                        <a rel="nofollow" href={`https://auth.myregplates.com/authorize?response_type=code&client_id=jz2Srv6g7oNYPiWJkoI9SoNcnsUzIpPc&redirect_uri=${window.location.origin}&scope=SCOPE&state=STATE&screen_hint=signup`}>
                            <div id="action-button-2">
                                <p>Sign Up <span className="arrow">❯</span></p>
                            </div>
                        </a>
                        <button style={{background: "none", border: "none"}} onClick={() => loginWithRedirect()}>
                            <p style={{fontWeight: "700", marginLeft: "1.5rem", color: "rgb(1, 60, 119)", fontSize: "1rem"}}>Log In</p>
                        </button>
                    </div> 
                </div>

                <div className="about-hero-img"></div>
            </div>

           
        </div>
    )
}

export default SignIn