import React, { useEffect }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import './App.css';
import './marquee.css';
import './About.css';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'

function ComingSoon() {
    const { loginWithRedirect } = useAuth0();
    
    return (
        <div>
           <div id="hero" className="about-hero">
                <div id="hero-text" className="about-hero-text">
                    <h1>Auctions will be live soon!</h1>
                    <h2>Our auction page will be available soon. In the meantime, you can still submit your plates for auction, they will be available as soon as we launch!</h2>
                    <div style={{display: "flex", alignItems: "center"}}>   
                        <Link to="/list">
                            <div id="action-button-2">
                                <p>Submit Plate <span className="arrow">❯</span></p>
                            </div>
                        </Link>
                    </div> 
                </div>

                <div className="about-hero-img"></div>
            </div>

           
        </div>
    )
}

export default ComingSoon