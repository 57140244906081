import React, { useEffect, useState } from 'react'
import './plates.css'
import './FAQ.css'

function FAQ() {
    const [faq1, showFaq1] = useState(false);
    const [faq2, showFaq2] = useState(false);
    const [faq3, showFaq3] = useState(false);
    const [faq4, showFaq4] = useState(false);
    const [faq5, showFaq5] = useState(false);
    const [faq6, showFaq6] = useState(false);
    const [faq7, showFaq7] = useState(false);
    const [faq8, showFaq8] = useState(false);
    const [faq9, showFaq9] = useState(false);
    const [faq10, showFaq10] = useState(false);

    useEffect(() => {
        document.title = "FAQ | MyRegPlates";
        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', 'Take a look at our FAQ if you have any questions about our service!')

        const el2 = document.querySelector("link[rel='canonical']");
        el2.setAttribute('href', 'https://myregplates.com/faq')
    }, [])

    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>FAQ</h1>
                </div>

                <div className="faq-question" onClick={() => showFaq1(!faq1)}>
                   {!faq1 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                   <span className="question-body">How do I list my number plate in the auction?</span> 
                </div>

                {faq1 && 
                    <div className="faq-answer">
                        Firstly, please sign up to create a MyRegPlates account. Then, Head to the 'Sell' section to list your registration plate. You can customise the price and duration of your registration. Also, you will be required to provide a certificate to ensure you have proof of ownership. E.g. V750 / V778. 
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq2(!faq2)}>
                    {!faq2 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">What is the difference between V750 and V778?</span> 
                </div>

                {faq2 && 
                    <div className="faq-answer">
                       V750 is for new registrations. For example, in cases where the number has never been used on a vehicle before. A V778 is for when a number has previously been registered to a vehicle, but it isn't any more and you still want the rights to the registration plate.
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq3(!faq3)}>
                    {!faq3 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">How do I bid for a number plate in the auction?</span> 
                </div>

                {faq3 && 
                    <div className="faq-answer">
                        Firstly, please sign up and create an account. Then, you can bid for a number plate in the auction. Also, ensure that you have a V5C, logbook, in your name to transfer the registration plate you win to your vehicle.
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq4(!faq4)}>
                    {!faq4 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">What is the commission you charge?</span> 
                </div>

                {faq4 && 
                    <div className="faq-answer">
                       <b>Buyers:</b> 10% transaction fee, £80 assignment fee<br></br>
                       Sellers do not pay any fees.
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq5(!faq5)}>
                    {!faq5 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">When does the seller receive their funds?</span> 
                </div>

                {faq5 && 
                    <div className="faq-answer">
                        The seller will receive their funds when the buyer successfully assigns the registration plate to their chosen vehicle. This ensures that both parties are safe and secure throughout the process. The guideline expected date to receive the funds is 7 working days, however our team will keep you updated every step of the way.
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq6(!faq6)}>
                    {!faq6 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">What do I need to provide to sell my registration plate?</span> 
                </div>

                {faq6 && 
                    <div className="faq-answer">
                       After signing up, you will only need to upload a copy of your V750 / V778 and payment card details once a successful sale takes place. Please go to the sell page for more information.
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq7(!faq7)}>
                    {!faq7 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">What does it mean when my plate is "Pending"?</span> 
                </div>

                {faq7 && 
                    <div className="faq-answer">
                       "Pending" refers to the plate being verified by MyRegPlates. We aim to review your plate within 24 hours.
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq8(!faq8)}>
                    {!faq8 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">What do I do if my payment fails?</span> 
                </div>

                {faq8 && 
                    <div className="faq-answer">
                       Visit your profile page and add a new payment card. Then, retry the transaction. 
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq9(!faq9)}>
                    {!faq9 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">How long does it take to assign a registration I have won in the auction to my vehicle? </span> 
                </div>

                {faq9 && 
                    <div className="faq-answer">
                       The plate is assigned on behalf of the buyer by MyRegPlates to ensure that both the seller and buyer are protected throughout the process. It usually takes approximately 7 working days to assign the registration plate to your vehicle. However, it may take longer, subject to your vehicle being taxed and having valid MOT. To ensure a smooth assignment process, please check that your vehicle is taxed and has a valid MOT.
                    </div>
                }

                <div className="faq-question" onClick={() => showFaq10(!faq10)}>
                    {!faq10 ? (<span className="question-q">▾</span>):(<span className="question-q">▴</span>)}
                    <span className="question-body">Why did my auction end later than the originally specified date and time?</span> 
                </div>

                {faq10 && 
                    <div className="faq-answer">
                      To prevent 'bid sniping', bids placed within the final 15 minutes of an auctions end date will extend the auction by a further 15 minutes.
                    </div>
                }
            </div>
        </div>
    )
}

export default FAQ
