import React, { useEffect }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import './App.css';
import './marquee.css';
import './About.css';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'

function Support() {
    const { loginWithRedirect } = useAuth0();

      
    useEffect(() => {
        document.title = "Support | MyRegPlates";
    }, [])

    
    return (
        <div>
           <div id="hero" className="about-hero">
                <div id="hero-text" className="about-hero-text">
                    <h1>Support</h1>
                    <h2>If you need help, please visit our <Link to="/faq" style={{textDecoration: "underline"}}>FAQ</Link>, 
                    <Link to="/about" style={{textDecoration: "underline"}}> About</Link> or
                    <Link to="/legal" style={{textDecoration: "underline"}}> Legal</Link>  pages. Alternatively, you may 
                    <Link to="/contact" style={{textDecoration: "underline"}}> contact</Link> our team with any further enquiries you may have.
                    <br></br><br></br>
                    To report a bug, visit the <Link to="/reportbug" style={{textDecoration: "underline"}}>Report Bug</Link> page.
                    </h2>
                
                    <div style={{display: "flex", alignItems: "center"}}>   
                        <Link to="/">
                            <div id="action-button-2">
                                <p>Back to Home <span className="arrow">❯</span></p>
                            </div>
                        </Link>
                    </div> 
                </div>

                <div className="about-hero-img"></div>
            </div>

           
        </div>
    )
}

export default Support