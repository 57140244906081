import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import clock from './res/clock.png'
import moment from 'moment';

const Table = ({ data, admin, status, buyer, perspective, valuations }) => {
    const HIGHEST_BID  = 3;
    const LOWEST_BID   = 4;
    const HIGHEST_BUY  = 1;
    const LOWEST_BUY   = 2;
    const TIME_LEFT    = 0;
    const PLATE_LENGTH = 5;
    const FEATURE      = 6;

    const PERSPECTIVE_BUYER  = 0;
    const PERSPECTIVE_SELLER = 1;

    const [query, setQuery] = useState('');
    const [sort, setSort] = useState(FEATURE);
    const [toggle1, setToggle1] = useState(false);

    function sortBy(by, a, b){
        switch(by){
            case HIGHEST_BID: return a.highest_bid > b.highest_bid;
            case LOWEST_BID:  return a.highest_bid < b.highest_bid;
            case HIGHEST_BUY: return a.buyout_price > b.buyout_price;
            case LOWEST_BUY:  return a.buyout_price < b.buyout_price;
            case PLATE_LENGTH: return a.url.length < b.url.length;
            case TIME_LEFT:{
                var d1 = new Date(a.expires_on);
                var d2 = new Date(b.expires_on);
                return d1 > d2;
            }
            case FEATURE:     return a.feature_level < b.feature_level;
        }
    }

    function filterBy(plate){
        if(!toggle1){
            return plate.name.toUpperCase().includes(query.toUpperCase());
        }else{
            return plate.name.toUpperCase().includes(query.toUpperCase()) && plate.dateless;
        }
    }

    function _setToggle1(event){
        setToggle1(!toggle1);
    }

    function getStatus(status, perspective){
        if(status === 'finished_won' || status === 'finished_buyout' || status === 'finished_payment_failed' || status === 'finished_retried'){
            if(perspective == PERSPECTIVE_SELLER){
                return 'Buyer Found';
            }else if (perspective == PERSPECTIVE_BUYER){
                if (status === 'finished_payment_failed'){
                    return 'Payment Failed';
                }else{
                    return 'Completed';
                }
            }
        }

        if(status === 'finished_below_min'){
            return 'Ended';
        }

        if(status === 'verified'){
            if(perspective == PERSPECTIVE_SELLER){
                return 'Active';
            }
        }

        if(status === 'unverified'){
            if(perspective == PERSPECTIVE_SELLER){
                return 'Pending';
            }
        }

        if(status === 'unverified_declined'){
            if(perspective == PERSPECTIVE_SELLER){
                return 'Declined';
            }
        }
    }

    function getStatus2(status){
        if(status === 'finished_won' || status === 'finished_buyout' || status === 'finished_payment_failed' || status === 'finished_retried'){
            if(status === 'finished_payment_failed'){
                return 1;
            }else{
                return 0;
            }
        }

        if(status === 'finished_below_min'){
            return 2;
        }

        if(status === 'verified'){
            return 1;
        }

        if(status === 'unverified'){
            return 1;
        }

        if(status === 'unverified_declined'){
            return 2;
        }
    }

    function gdd(end){
        var e = moment(end);
        var d = e.diff(Date.now(), "days");
        return d;
    }

    function getNotifyClass(c){
        if (c == 0) return 'ok-field'
        else if (c == 1) return 'warning-field'
        else if (c == 2) return 'error-field'
    }

    if(valuations) {
        return (
        <div>
            <table className="plate-table">
                <tr className="t-header">
                    <th className="field-h mobile-hidden">Plate</th>
                    <th className="field-h mobile-hidden">Value</th>
                    <th className="field-h mobile-hidden">View</th>
        
                </tr>

                {data.reverse().map(plate => (
                    <tr className="plate-row">
                        <td>
                        <Link to={`/admin/valuations/${plate.category}`}>
                            <div className="plate-name field">{plate.category}</div>
                        </Link>
                        </td>

                        <td className="mobile-hidden">{plate?.value}</td>
                        <td className="mobile-hidden"><Link to={`/admin/valuations/${plate.category}`}><div className="plate-view field">View</div></Link></td>

                    </tr>
                ))}
            </table>
        </div>
        )
    }else {
    return (
        <div>
            <table className="plate-table">
                <tr className="t-header">
                    <th className="field-h no-pad-left mobile-header">
                        <div className="table-control">
                            <input className="search-bar" type="text" name="plate" id="plate-input" placeholder="Search for a plate..." onChange={e => setQuery(e.target.value)}></input>
                            <div className="dateless-wrapper">
                                <input type="checkbox" className="dateless-check" name="tosinput" onChange={(e) => _setToggle1(e)}></input>
                                <label for="tosinput" className="dateless-check-label">Dateless Only</label>
                            </div>
                            
                        </div>
                    </th>
                    

                    {buyer ? (
                        <>
                            <th className="field-h mobile-hidden">Bought For</th>
                            <th className="field-h mobile-hidden">Bought On</th>
                        </>
                    ):(
                        <>
                            <th className="field-h mobile-hidden">Current Price</th>
                            <th className="field-h mobile-hidden">Buy Now</th>
                            <th className="field-h mobile-hidden">Time Left</th>
                        </>
                    )}
                        

                    {status ? (
                        <th className="field-h mobile-hidden">Status</th>
                    ):(
                        null
                    )}
                        

                    {admin ? (
                        <th className="field-h">Status</th>
                    ):(
                        null
                    )}

                    <th className="field-h mobile-hidden">
                        <form>
                            <select name="sort" className="sort-input" onChange={e => setSort(e.target.selectedIndex)}>
                                <option value="" disabled selected hidden>Sort by</option>
                                <option value="time">Time Remaining</option>
                                <option value="buyhi">Highest Buyout</option>
                                <option value="buylo">Lowest Buyout</option>
                                <option value="bidhi">Highest Bid</option>
                                <option value="bidlo">Lowest Bid</option>
                                <option value="length">Plate Length</option>
                            </select>
                            {/*<input type="submit" name="submit" className="theme-button" value="Sort"></input>
                            */}
                        </form>
                    </th>
                </tr>

                {data.reverse().filter((plate) => filterBy(plate)).sort((a, b) => sortBy(sort, a, b)).map(plate => (
                    <tr className="plate-row">
                        <td>
                        <Link to={`/plates/${plate.url}`}>
                            <div className="plate-name field">
                                <p className="plate-name-p">{plate.name}</p>
                                {plate.highest_bid >= plate.minimum_price ? (
                                    <div className="plate-name-reserve">Reserve Met</div>
                                ):(
                                    null
                                )}
                                    
                            </div>
                            <div className="plate-mobile-info">
                                <div className="mobile-time">
                                    <img src={clock} width={24} height={24}></img>
                                    <Countdown date={Date.parse(`${plate.expires_on}`)} />
                                </div>

                                <p className="mobile-price">
                                    {plate.start_price > plate.highest_bid ? (
                                        <>
                                        £{plate.start_price.toLocaleString()}
                                        </>
                                    ):(
                                        <>
                                        £{plate.highest_bid.toLocaleString()}
                                        </>
                                    )}
                                </p>
                            </div>
                        </Link>
                        </td>

                        {buyer ? (
                            <>
                                <td className="mobile-hidden">
                                    <div className="plate-bid field">
                                        {plate.start_price > plate.highest_bid ? (
                                            <>
                                            £{plate.start_price.toLocaleString()}
                                            </>
                                        ):(
                                            <>
                                            £{plate.highest_bid.toLocaleString()}
                                            </>
                                        )}
                                    </div>
                                </td>
                                <td className="mobile-hidden"><div className="plate-buyout field">{moment(plate.sold_at).format('DD MMMM YYYY')}</div></td>
                            </>
                        ):(
                            <>
                                <td className="mobile-hidden">
                                    <div className="plate-bid field">
                                        {plate.start_price > plate.highest_bid ? (
                                            <>
                                            £{plate.start_price.toLocaleString()}
                                            </>
                                        ):(
                                            <>
                                            £{plate.highest_bid.toLocaleString()}
                                            </>
                                        )}
                                    </div>
                                </td>
                                
                                {plate.buyout_price > 0 ? (
                                    <td className="mobile-hidden"><div className="plate-buyout field">£{plate.buyout_price.toLocaleString()}</div></td>
                                ):(
                                    <td className="mobile-hidden"><div className="plate-buyout field">N/A</div></td>
                                )}
                                
                                {plate.status.includes('finished') ? (
                                    <td className="mobile-hidden"><div className="plate-time field">Finished</div></td>
                                ):(
                                    plate.status.includes('unverified') ? (
                                        <td className="mobile-hidden"><div className="plate-time field">Pending</div></td>
                                    ):(
                                        <td className="mobile-hidden">
                                            <div className="plate-time field">
                                                {gdd(plate.expires_on) > 0 ? (
                                                    <p>{gdd(plate.expires_on)} Days</p>
                                                ):(
                                                    <Countdown date={Date.parse(`${plate.expires_on}`)} />
                                                )}
                                            </div>
                                        </td>
                                    ) 
                                    
                                )}
                                    
                            </>
                        )}
                        
                        
                        {status ? (
                            <td className="mobile-hidden">
                                <div className={"plate-time field " + getNotifyClass(getStatus2(plate.status))}>
                                    {getStatus(plate.status, perspective)}
                                </div>
                            </td>
                        ):(
                            null
                        )}

                        {admin ? (
                            <>
                            <td><div className="plate-buyout field">{plate.status}</div></td>
                            <td><Link to={`/admin/${plate.url}`}><div className="plate-view field">View</div></Link></td>
                            </>
                        ):(
                            <td className="mobile-hidden"><Link to={`/plates/${plate.url}`}><div className="plate-view field">View</div></Link></td>
                        )}

                    </tr>
                ))}
            </table>
        </div>
    )
    }
}

export default Table