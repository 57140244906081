import React, { useEffect, useState } from 'react'
import './plates.css'

function Cookies() {
    useEffect(() => {
        document.title = "Cookie Policy | MyRegPlates";
    }, [])

    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>Cookie Policy</h1>
                </div>

                <p style={{lineHeight: "1.5rem"}}>
MyRegPlates uses cookies to enhance your user experience and to provide personalized services. Cookies are small text files that are stored on your device when you visit our website.
<br/><br/>
The cookies we use on our website fall into two categories: necessary cookies and optional cookies.
<br/><br/>
<h3><b>Necessary Cookies:</b></h3>
<br/>
These cookies are essential for the website to function properly. They enable us to provide you with secure access to your account, to remember your preferences, and to improve the performance of our website. The necessary cookies we use include:
<br/><br/>
- Authentication Cookies: These cookies are used to keep you logged in to your account and to remember your authentication information, including your access token provided by Auth0.
<br/>
- Notification Count Cookies: These cookies are used to keep track of the number of notifications you have received, so that we can display an accurate count on the notification icon.
<br/><br/>
<h3><b>Optional Cookies:</b></h3>
<br/>
These cookies are not necessary for the website to function properly, but they help us to understand how our website is used and to improve your experience. The optional cookies we use include:
<br/><br/>
- Analytics Cookies: These cookies allow us to collect information about how visitors use our website, such as which pages are visited most often and how long visitors spend on each page. This helps us to improve the performance of our website and to provide a better user experience.
<br/>
- Marketing Cookies: These cookies are used to personalize the advertising that you see on our website and on other websites. They allow us to show you ads that are relevant to your interests and browsing history.
<br/><br/>
You have the option to opt-out of the optional cookies by adjusting your browser settings. Please note, however, that disabling cookies may affect the functionality of our website and may limit your access to certain features.
<br/><br/>
By continuing to use our website, you consent to the use of cookies as outlined in this Cookies Policy.
<br/><br/>
If you have any questions or concerns about our use of cookies, please contact us using the information provided on the Contact Us page.
                </p>
                
            </div>
        </div>
    )
}

export default Cookies