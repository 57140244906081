import React, { useState, useEffect } from 'react'
import { API_URL } from './common';
import { useAuth0, User } from "@auth0/auth0-react";
import { FileUploader } from "react-drag-drop-files";
import { Tooltip } from 'react-tooltip'
import 'react-credit-cards-2/es/styles-compiled.css';
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import SignIn from './SignIn'

import './App.css';
import './Form.css';
import './About.css'
import Loading from './Loading';

function ReportBug() {
  const [apiStatus, setStatus] = useState();
  const [file, setFile] = useState(null);
  const [showSubmit, setShowSubmit] = useState(true);
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    setShowSubmit(false);

    var plateData = {};

    if(event.target.description.value === ''){
      setStatus("You must enter all the required information.");
      setShowSubmit(true);
      return;
    }

    plateData.name = event.target.sprice.value;
    plateData.duration = event.target.duration.value;
    plateData.description = event.target.description.value;

    console.log(JSON.stringify(plateData));

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(plateData),
        mode: 'cors'
    };

    await fetch(API_URL + "bugreports/create", requestOptions).then(
      response => response.json()
    ).then((result) => {
      //setStatus(result);
      if(result.includes('successfully')){
        navigate(`/reportbug/submitted`)
      }else{
        setStatus(result);
      }
    }).catch((error) => {
      setStatus(error);
    });

    setShowSubmit(true);
  }

  
  useEffect(() => {
    document.title = "Report Bug | MyRegPlates";
  }, [])


  return (
    <>
      <div id="form-wrapper">
        <div id="form-section">
              <>
                <h1 style={{paddingTop:"2rem", fontWeight: "900"}}>Report Bug</h1>
                <p>If you have encountered a bug on the MyRegPlates website, you may report it using the form below.
                </p>

              </>

              <form onSubmit={handleSubmit}>
                <label>
                  <span style={{paddingTop: "1rem"}}>Category<span style={{color: "red"}}>*</span></span>
                  <select name="duration" className="duration-input">
                    <option value="1">Visual/Layout</option>
                    <option value="2">Payment/Account Info</option>
                    <option value="3">Auction</option>
                    <option value="4">Document/Form Submission</option>
                    <option value="5">Other</option>
                  </select>
                </label>

                <label>
                  <span style={{paddingTop: "1rem"}}>Your Email</span>
                  <input type="text" name="sprice" className="duration-input"></input>
                </label>

                <label>
                  <span style={{paddingTop: "1rem"}}>Description<span style={{color: "red"}}>*</span></span>
                  <span style={{color: "gray", fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.5rem"}}>Describe the bug in as much detail as possible, including the specifications of your device and web browser.</span>
                  <textarea type="text" name="description" className="duration-input" style={{marginTop: "1rem", minHeight: "100px", maxWidth: "300px"}}></textarea>
                </label>

                {showSubmit ? (
                  <input style={{marginTop: "2rem"}} type="submit" name="submit" className="submit-input" text="Submit"></input>
                ):(
                  <Loading></Loading>
                )}
                  
              </form>

              {apiStatus ? (
                <>
                <p className="card-status">{apiStatus}</p>
                <div style={{height: 20}}>

                </div>
                </>
              ):(
                null
              )}

        </div>
      </div>
    </>
  )
}

export default ReportBug