import React, { useEffect, useState } from 'react'
import './plates.css'

function DataProtection() {
    useEffect(() => {
        document.title = "Data Protection | MyRegPlates";
    }, [])

    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>Data Protection</h1>
                </div>

                <p style={{lineHeight: "1.5rem"}}>
MyRegPlates is committed to protecting your personal information and ensuring that your privacy is respected. We collect and store your personal information in accordance with applicable data protection laws and regulations. This Data Protection Policy outlines how we collect, use, and protect your personal information.
<br/><br/>
<h2>What Personal Information Do We Collect?</h2>
<br/>
We may collect the following personal information when you use our website:
<br/><br/>
<li>Your name, email address, phone number, and other contact details.</li>
<li>Your bank details and card details, which are collected securely through our payment processor.</li>
<li>Your vehicle log books and V750 / V778 certificates, which are necessary to complete the transfer of ownership of a registration number.</li>
<br/>
<h2>How Do We Use Your Personal Information?</h2>
<br/>
We use your personal information for the following purposes:
<br/><br/>
<li>To enable you to buy and sell registration numbers through our website.</li>
<li>To process your payments and fulfill your orders.</li>
<li>To communicate with you about your orders and to provide customer support.</li>
<li>To comply with our legal and regulatory obligations, such as maintaining accurate records for tax purposes.</li>
<li>To improve our website and the services we provide.</li>
<br/>
<h2>How Do We Protect Your Personal Information?</h2>
<br/>
We take the security of your personal information seriously and have implemented appropriate technical and organizational measures to protect it against unauthorized access, disclosure, or use. We use industry-standard security protocols to encrypt and secure your personal information during transmission and storage.
<br/><br/>
We also have strict procedures in place for handling and storing physical documents, such as vehicle log books and V750 certificates, to ensure that they are kept secure and confidential.
<br/><br/>
<h2>How Long Do We Retain Your Personal Information?</h2>
<br/>
We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, as well as to comply with our legal and regulatory obligations. This may include retaining your personal information for a period of time after your account has been closed or your order has been fulfilled.
<br/><br/>
<h2>Your Rights and Choices</h2>
<br/>
You have the following rights with respect to your personal information:
<br/><br/>
<li>The right to access and receive a copy of your personal information.</li>
<li>The right to correct or update your personal information.</li>
<li>The right to object to the processing of your personal information.</li>
<li>The right to have your personal information erased (in certain circumstances).</li>
<li>The right to restrict the processing of your personal information (in certain circumstances).</li>
<br/>
You may exercise your rights by contacting us using the information provided on the Contact Us page.
<br/><br/>
<h2>Contact Us</h2>
<br/>
If you have any questions or concerns about our Data Protection Policy or our use of your personal information, please contact us using the information provided on the Contact Us page.


                </p>
                
            </div>
        </div>
    )
}

export default DataProtection