import React, { useEffect }  from 'react'
import { useAuth0, User } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import './App.css';
import './marquee.css';
import './About.css';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'

function Contact() {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        document.title = "Contact | MyRegPlates";

        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', 'Get in touch with us if you have any queries about our platform or service!')

        const el2 = document.querySelector("link[rel='canonical']");
        el2.setAttribute('href', 'https://myregplates.com/contact')
    }, [])
    
    return (
        <div>
           <div id="hero" className="about-hero">
                <div id="hero-text" className="about-hero-text">
                    <h1>Contact Us</h1>
                    <h2>The MyRegPlates team is happy to answer any questions you may have.</h2>
                    <h3>Email:</h3>
                    <p style={{padding:"0.5rem 0"}}><a href="mailto:info@myregplates.com">info@myregplates.com</a></p>
                    <br></br>
                    <h3>Social Media:</h3>
                    <div id="social-container">
                    <a href="https://www.facebook.com/myregplates">
                        <div className="social-icon contact-icon">
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"><title>facebook</title><path d="M21.79 1H2.21C1.54 1 1 1.54 1 2.21v19.57c0 .68.54 1.22 1.21 1.22h10.54v-8.51H9.9v-3.33h2.86V8.71c0-2.84 1.74-4.39 4.27-4.39.85 0 1.71.04 2.56.13v2.97h-1.75c-1.38 0-1.65.65-1.65 1.62v2.12h3.3l-.43 3.33h-2.89V23h5.61c.67 0 1.21-.54 1.21-1.21V2.21C23 1.54 22.46 1 21.79 1z" fill="currentColor" ></path></svg>
                        </div>
                    </a>

                    <a href="https://www.tiktok.com/@myregplates">
                        <div className="social-icon contact-icon">
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"><title>tiktok</title><g fill="currentColor"> <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z"></path></g></svg>
                        </div>
                    </a>

                    <a href="https://www.instagram.com/myregplates">
                        <div className="social-icon contact-icon">
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"><title>instagram</title><g fill="currentColor"><path d="M21.15 2.85C19.05.74 16.23 1 12 1 8.04 1 5 .69 2.85 2.85.74 4.95 1 7.77 1 12c0 3.95-.31 7 1.85 9.15C4.95 23.26 7.77 23 12 23c3.96 0 7 .31 9.15-1.85C23.25 19.05 23 16.23 23 12c0-4.31.24-7.07-1.85-9.15zm-1.4 16.9c-1.37 1.37-3.18 1.27-7.75 1.27-4.29 0-6.34.15-7.75-1.27-1.44-1.44-1.27-3.51-1.27-7.75 0-4.23-.15-6.33 1.27-7.75C5.66 2.84 7.6 2.98 12 2.98c4.23 0 6.33-.15 7.75 1.27 1.38 1.38 1.27 3.22 1.27 7.75 0 4.24.15 6.34-1.27 7.75z"></path><path d="M12 6.35a5.65 5.65 0 10.001 11.301A5.65 5.65 0 0012 6.35zm0 9.32c-2.02 0-3.67-1.64-3.67-3.67 0-2.03 1.64-3.67 3.67-3.67 2.03 0 3.67 1.64 3.67 3.67 0 2.02-1.65 3.67-3.67 3.67zM17.87 4.81c-.73 0-1.32.59-1.32 1.32 0 .73.59 1.32 1.32 1.32.73 0 1.32-.59 1.32-1.32 0-.73-.59-1.32-1.32-1.32z"></path></g></svg>
                        </div>
                    </a>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>   
                        <Link to="/">
                            <div id="action-button-2">
                                <p>Back to Home <span className="arrow">❯</span></p>
                            </div>
                        </Link>
                    </div> 
                </div>

                <div className="about-hero-img"></div>
            </div>

           
        </div>
    )
}

export default Contact