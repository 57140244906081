import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useAuth0, User } from "@auth0/auth0-react";
import { API_URL } from './common';
import Loading from './Loading';

const SetupForm = ({secret}) => {
  const stripe = useStripe();
  const elements = useElements();

  const { isAuthenticated, user, getAccessTokenSilently} = useAuth0();

  const [errorMessage, setErrorMessage] = useState(null);
  const [okMessage, setOkMessage] = useState(null);
  const [display, setDisplay] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (!stripe || !elements) {
      return null;
    }

    const {error} = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/my/profile?view=payment',
      },
      redirect: 'if_required'
    }).then(async function() {
        await checkForCard();
    });

    if (error) {
        console.log(error);
      setErrorMessage(error.message);
    } else {
       // await checkForCard();
    }
    
    setLoading(false);
  };

  async function checkForCard(){
    if (!stripe) {
        return;
      }
      // Retrieve the "setup_intent_client_secret" query parameter appended to
      // your return_url by Stripe.js
      const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
      );

      console.log(secret);

      // Retrieve the SetupIntent
      stripe
        .retrieveSetupIntent(secret)
        .then(async ({setupIntent}) => {
          // Inspect the SetupIntent `status` to indicate the status of the payment
          // to your customer.
          //
          // Some payment methods will [immediately succeed or fail][0] upon
          // confirmation, while others will first enter a `processing` state.
          //
          // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
          switch (setupIntent.status) {
            case 'succeeded':
              await updateUserDetails(setupIntent);
              break;
  
            case 'processing':
              setDisplay(false);
              setOkMessage("Processing payment details. We'll update you when processing is complete.");
              break;
  
            case 'requires_payment_method':
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              setErrorMessage('Failed to process payment details. Please try another payment method.');
              break;
          }
    });
    }

  async function updateUserDetails(intent){
    var accessToken = await getAccessTokenSilently();

    var cardInfo = {};
    cardInfo.intent = intent;
    cardInfo.email = user.email;
    cardInfo.id = user.sub;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken },
        body: JSON.stringify(cardInfo),
        mode: 'cors'
    };
    
    await fetch(API_URL + "payment/addcard", requestOptions).then(
        response => response.json()
    ).then((result) => {
        if(result.includes('successfully')){
            setDisplay(false);
            setOkMessage("New card details added successfully.");
            window.history.replaceState(null, "MyRegPlates", "/my/profile?view=payment&card=true");
            window.location.reload();
        }else{
            setErrorMessage(result);
        }
    }).catch((error) => {
        setErrorMessage(error);
    });
  }

  return (
    <form onSubmit={handleSubmit} style={{display:"flex", flexDirection: "column", maxWidth: "300px"}}>
      {display ? (
        <>
            <PaymentElement />
            {loading ? (
                <Loading/>
            ):(
                <button disabled={!stripe} style={{marginTop: "1rem", alignSelf: "flex-start"}}className="theme-button account-button">Submit</button>
            )}
                
        </>
      ):(
        null
      )}
        
      {errorMessage && <p className='card-status' style={{alignSelf: "flex-start"}}>{errorMessage}</p>}
      {okMessage && <p className='success-box' style={{alignSelf: "flex-start"}}>{okMessage}</p>}
    </form>
  );
};

export default SetupForm;