const API_URL_ONLINE = "https://mrp-backend-6s5p.onrender.com/api/"
const API_URL_OFFLINE = "http://localhost:24424/api/"
const WS_URL_OFFLINE = "http://localhost:24424"
const WS_URL_ONLINE = "https://mrp-backend-6s5p.onrender.com"

const API_URL_ONLINE_ADMIN = API_URL_ONLINE + "admin/"
const API_URL_OFFLINE_ADMIN = API_URL_OFFLINE + "admin/"

export const API_URL = API_URL_ONLINE;
export const API_URL_ADMIN = API_URL_ONLINE_ADMIN;
export const WS_URL = WS_URL_ONLINE;
