import React, { useState, useEffect } from 'react'
import { API_URL } from './common';
import { useAuth0, User } from "@auth0/auth0-react";
import { FileUploader } from "react-drag-drop-files";
import { Tooltip } from 'react-tooltip'
import 'react-credit-cards-2/es/styles-compiled.css';
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import SignIn from './SignIn'

import './App.css';
import './Form.css';
import './About.css'
import Loading from './Loading';

function CreatePlate() {
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [apiStatus, setStatus] = useState();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [file, setFile] = useState(null);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const fileTypes = ["JPG", "PNG", "PDF"];
  const [data3, setData3] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSubmit, setShowSubmit] = useState(true);
  const [relist, setRelist] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [previewPrice, setPreviewPrice] = useState(0);
  const [plate, setPlate] = useState(null);
  const navigate = useNavigate();

  function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

  function setPreview(event){
    if(isNumber(event.target.value)){
        var price = Number(event.target.value);
        price = Math.ceil(price);

        setPreviewPrice(price);
    }else{
        setPreviewPrice(0);
    }
}

function getPreviewPrice(price){
  var num = Math.round((price - ((price / 100) * 3.5)) * 100) / 100;
  return num.toFixed(Math.max(2, (num.toString().split('.')[1] || []).length));
}

  const handleChange = (file) => {
    setFile(file);
    //console.log(file.name);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setShowSubmit(false);

    var plateData = {};

    if(file == null){
      setStatus("You must upload a valid image/document file that displays your retention certificate");
      setShowSubmit(true);
      return;
    }

    const base64 = await convertBase64(file)
    const parts = base64.split(",");

    if(!event.target.tosinput.checked){
      setStatus("You must accept the Terms of Service to submit this auction.");
      setShowSubmit(true);
      return;
    }

    if(!event.target.tosinput2.checked){
      setStatus("You must accept the Terms of Service to submit this auction.");
      setShowSubmit(true);
      return;
    }

    if(!event.target.certinput.checked){
      setStatus("You must confirm that your license plate is on a retention certificate (V750)");
      setShowSubmit(true);
      return;
    }

    if(event.target.sprice.value === '' || event.target.plate.value === ''){
      setStatus("You must enter all the required information.");
      setShowSubmit(true);
      return;
    }

    if(Number(event.target.mprice.value) < Number(event.target.sprice.value)){
      if(Number(event.target.mprice.value) > 0){
        setStatus("The minimum price can't be less than the starting price.");
        console.log("less");
        setShowSubmit(true);
        return;
      }
    }

    plateData.name = event.target.plate.value;
    plateData.duration = event.target.duration.value;
    plateData.startprice = event.target.sprice.value;

    if(event.target.mprice.value === ''){
      plateData.minimumprice = "0";
    }else{
      plateData.minimumprice = event.target.mprice.value;
    }

    if(event.target.mprice.value === ''){
      plateData.buyoutprice = "0";
    }else{
      plateData.buyoutprice = event.target.bprice.value;
    }
    
    plateData.description = "";
    plateData.uid = user.sub;
    plateData.file = parts[1];
    plateData.file_name = file.name;


   // console.log(JSON.stringify(plateData));

    var accessToken = await getAccessTokenSilently();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
        body: JSON.stringify(plateData),
        mode: 'cors'
    };

    await fetch(API_URL + "auctions/create", requestOptions).then(
      response => response.json()
    ).then((result) => {
      //setStatus(result);
      if(result.includes('successfully')){
        const arr = result.split("*");
        navigate(`/plates/${arr[0]}`)
      }else{
        setStatus(result);
      }
    }).catch((error) => {
      setStatus(error);
    });

    setShowSubmit(true);
  }

  function _setToggle1(event){
  //  event.preventDefault();
    setToggle1(!toggle1);
  }

  function _setToggle2(event){
   // event.preventDefault();
    setToggle2(!toggle2);
  }

  async function getUserInfo(){
    var accessToken = await getAccessTokenSilently();
    const requestOptions = {
        method: 'GET',
        headers: {'authorization': 'Bearer ' + accessToken}
    };
    const request = await fetch(API_URL + `payment/info/${user.sub}`, requestOptions, {

    }).then((request => request.json()))
      .then((data) => {
        setData3(data)
        setLoading(false);
    }).catch(err => console.log(err)).then(() => {
        //setLoading(true)
    })
  }
  
  useEffect(() => {
    document.title = "MyRegPlates | List a plate for auction";

    const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', 'Submit your plate to our auction and begin receiving bids today!')

        const el2 = document.querySelector("link[rel='canonical']");
        el2.setAttribute('href', 'https://myregplates.com/list')
  }, [])

  useEffect(() => {
    if(isAuthenticated){
        getUserInfo();
    }
  }, [isAuthenticated]);

  return (
    <>
    {isAuthenticated ? (
      <div id="form-wrapper">
        <div id="form-section">
              {!relist ? (
                <>
                  <h1 style={{paddingTop:"2rem", fontWeight: "900"}}>List a plate for auction</h1>
                  <p>Fill out the fields below to submit a number plate for auction. <br></br>
                  Our team will review your submission within 24 hours. Your auction will go live as soon as the verification process is complete.
                  </p>
                 

                </>
              ):(
                <>
                  <h1 style={{paddingTop:"2rem", fontWeight: "900"}}>Relist plate</h1>
                  <p>If a buyer was not found your plate, you can relist it for auction immediately. <br></br>
                  You can change the parameters of the auction before you relist it.
                  </p>
                </>
              )}
                
              {!loading && !data3.bank ? (
                <>
                <p className="warning-box">⚠️ <a href="/my/profile?view=payment">You can't receive the funds from your completed auctions until you enter your bank account details.</a>
                    <a data-tooltip-id="bank-tooltip" data-tooltip-content="You need to provide your bank account details so that MyRegPlates can transfer you the funds from your sold auction plates."> <span style={{textDecoration: "underline"}}>Why?</span></a>
                </p>
                <Tooltip id="bank-tooltip" />
                </>
              ):(
                null
              )}

              <form onSubmit={handleSubmit}>
                <label>
                  <span>Registration Plate <span style={{color: "red"}}>*</span></span>
                  <input maxLength="10" type="text" name="plate" className="plate-input" disabled={relist}></input>
                </label>

                {/*<label>
                  Plate Description <br></br>
                  <span style={{color: "gray", fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.5rem"}}>You can provide a short description of your plate which will be shown on the auction page.</span>
                  <textarea type="text" name="description" className="price-input" style={{marginTop: "1rem", minHeight: "100px", maxWidth: "300px"}}></textarea>
              </label>*/}

                <label>
                  <span style={{paddingTop: "1rem"}}>Auction Duration <span style={{color: "red"}}>*</span></span>
                  <div className='duration-input-wrapper'>
                    <select name="duration" className="duration-input">
                      <option value="1">1 Week</option>
                      <option value="2">2 Weeks</option>
                      {/*<option style={{color: "red", fontWeight: "bold"}} value="3">5 Minutes (DEV ONLY)</option>*/}
                    </select>
                    <div className='duration-input-arrow'>▾</div>
                  </div>
                </label>

                <label>
                  <span style={{paddingTop: "1rem"}}>Auction Start Price <span style={{color: "red"}}>*</span></span>
                  <input type="number" name="sprice" className="price-input"></input>
                </label>

                <label>
                  <span style={{paddingTop: "1rem"}}>Reserve Price <span style={{color: "red"}}>*</span></span>
                  <span style={{color: "gray", fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.5rem"}}>Your plate will not sell if the auction doesn't reach the reserve price (exclusive of fees).</span>
                  <div style={{display: "flex"}} className="reserve-preview">
                    <input type="number" name="mprice" className="price-input" style={{width: "300px", minWidth: "300px"}} onChange={(e) => setPreview(e)}></input>
                    {/*<div className={"reserve-preview-popup " + (previewPrice > 0 ? "popup-show" : "")}>🛈 If this auction sells for £{previewPrice.toLocaleString()}, you will receive <span style={{fontWeight: "bold"}}>£{getPreviewPrice(previewPrice)}</span> (inclusive of all fees).</div>*/}
                  </div>
                </label>

                <label>
                  <label for="tosinput" className="tos-label" style={{fontSize: "1rem"}}>'Buy Now' Price</label>
                  <span style={{color: "gray", fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.5rem"}}>(Optional) Your plate can be bought immediately (after fees) for the set price.</span>
                  <input type="number" name="bprice" className="buyout-input"></input>
                </label>

                {!relist ? (
                  <>
                  <label> 
                    <span style={{paddingTop: "1rem"}}>Proof of Ownership <span style={{color: "red"}}>*</span></span>
                    <span style={{color: "gray", fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.5rem"}}>You must upload a file which clearly displays the certificate for this number plate</span>
                    <FileUploader handleChange={handleChange} name="file" classes="drop-zone" label="Upload or drop a file here (Max size 25MB)" minSize={0} maxSize={25} onSizeError={(file) => setStatus("The maximum file size is 100mb")} />
                  </label>

                  <div style={{paddingTop: "1rem"}} className="tos-wrapper">
                    <input type="checkbox" className="tos-input" name="certinput"></input>
                    <label for="certinput" className="tos-label"><span>I can confirm that my registration plate is on a retention certificate (V750 or V778). <span style={{color: "red"}}>*</span></span></label>
                  </div>
                  </>
                ):(
                  null
                )}

                <div className="tos-wrapper">
                    <input type="checkbox" className="tos-input" name="tosinput"></input>
                    <label for="tosinput" className="tos-label"><span>By submitting my registration plate, I agree to MyRegPlates <a href="/legal" style={{textDecoration: "underline"}}>terms and conditions</a> <span style={{color: "red"}}>*</span></span></label>
                </div>

                <div className="tos-wrapper">
                    <input type="checkbox" className="tos-input" name="tosinput2"></input>
                    <label for="tosinput2" className="tos-label"><span>For the duration of the auction, I agree to refrain from selling or auctioning this plate privately or outside of MyRegPlates, as outlined in the <a href="/legal" style={{textDecoration: "underline"}}>terms and conditions</a> <span style={{color: "red"}}>*</span></span></label>
                </div>

                {showSubmit ? (
                  <input style={{marginTop: "2rem"}} type="submit" name="submit" className="submit-input" text="Submit"></input>
                ):(
                  <Loading></Loading>
                )}
                  
              </form>

              {apiStatus ? (
                <>
                <p className="card-status">{apiStatus}</p>
                <div style={{height: 20}}>

                </div>
                </>
              ):(
                null
              )}

        </div>
      </div>
    ):(
      <SignIn/>
    )}
    </>
  )
}

export default CreatePlate