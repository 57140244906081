import React, { useEffect }  from 'react'
import './App.css';
import './marquee.css';
import './About.css';
//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'
import logo from './res/logo-white.svg'
import stars from './res/stars.svg'

function About() {
    useEffect(() => {
        document.title = "About | MyRegPlates";

        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', 'Learn more about MyRegPlates, our platform, and the services that we offer.')

        const el2 = document.querySelector("link[rel='canonical']");
        el2.setAttribute('href', 'https://myregplates.com/about')
    }, [])

    return (
        <div>
           <div id="hero" className="about-hero">
                <div id="hero-text" className="about-hero-text">
                    <h1>About Us</h1>
                    <h2>Welcome to MyRegPlates, the premier destination for buying and selling personalised vehicle registrations through online auctions. We offer an easy, safe, and efficient way to search for, bid on, and win unique and memorable registration plates for your vehicles.</h2>
                    
                    {/*
                    <div style={{paddingBottom: "2rem"}}>
                        <a className="tp-out" href="https://uk.trustpilot.com/review/myregplates.com">
                            <div className="ratings-component">
                                <img src={stars} height={30} />
                                <div class="rating-text-2"><strong>4.6</strong> out of 5 based on <strong>250 reviews</strong></div>
                                <img src={logo} height={20}/>
                            </div>
                        </a>
                    </div>
    */}
                    <Link to="#about2-content-bottom">
                            <div id="action-button">
                                Learn More <span className="arrow">❯</span>
                            </div>
                    </Link>
                </div>

                <div className="about-hero-img"></div>
            </div>

            <div id="about2-content-bottom">
                <h1>Registration Plate Specialists</h1>

                <p id="about-content-text" style={{marginTop: "0", fontSize: "18px"}}>At MyRegPlates, we understand that owning a personalised registration is more than just having a unique identifier for your car, it's a statement of individuality and personal style. Our platform features a wide range of registration plates that cater to different tastes and preferences. Whether you're looking for a special combination of letters and numbers, or a unique symbol that represents your personality, we have something for everyone. </p>
                <div id="sign-up-pane-container">
                    <Link to="/plates">
                            <div id="action-button">
                                Browse Plates <span className="arrow">❯</span>
                            </div>
                    </Link>
                </div>
            </div>

            <div id="sign-up-wrapper">
                <div id="sign-up">
                    <h1>Why Choose Us?</h1>

                  

                    <p id="about-content-text">Our online auction platform is designed to make buying and selling personalised vehicle registrations easy, secure, and enjoyable. With a user-friendly interface, comprehensive search tools and competitive pricing, you can easily browse through our collection of plates and place your bid on the one that catches your eye. Our secure payment gateway and professional service guarantee that all transactions are safe and hassle-free. </p>

                    <div id="sign-up-pane-container">
                        <a rel="nofollow" href={`https://auth.myregplates.com/authorize?response_type=code&client_id=jz2Srv6g7oNYPiWJkoI9SoNcnsUzIpPc&redirect_uri=${window.location.origin}&scope=SCOPE&state=STATE&screen_hint=signup`}>
                            <div id="action-button-2">
                                <p>Sign Up <span className="arrow">❯</span></p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About