import React, { useEffect, useState, useRef } from 'react'
import { useParams } from "react-router";
import { API_URL, WS_URL } from './common';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useAuth0, User } from "@auth0/auth0-react";
import './plate.css';
import io from 'socket.io-client';
import saveIcon from './res/save.png'
import shareIcon from './res/share.png'
import eyeIcon from './res/eye.svg'
import clock from './res/clock.png'
import qm from './res/qm.svg'
import verified from './res/verified.png'
import ImageViewer from 'react-simple-image-viewer';
import Loading from './Loading';
import { FileUploader } from "react-drag-drop-files";
import { useInView } from "react-intersection-observer";
import NotFound from './NotFound';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Form.css'
import { Tooltip } from 'react-tooltip';


function Plate() {
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [data, setData] = useState();
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [plateDate, setPlateDate] = useState('');
    const bids = useRef(data2);
    const [apiStatus, setStatus] = useState();
    const [apiStatus3, setStatus3] = useState();
    const [showSubmit, setShowSubmit] = useState(true);
    const fileTypes = ["JPG", "PNG", "PDF"];
    const [status2, setStatus2] = useState('');
    const [statusColor, setStatusColor] = useState('white');
    const [highBid, setHighBid] = useState();
    const [showBuy, setShowBuy] = useState(true);
    const [won, setWon] = useState(false);
    const [end, setEnd] = useState(false);
    const { isAuthenticated, user} = useAuth0();
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imgs, setImgs] = useState([]);
    const [file, setFile] = useState(null);
    const [relist, setRelist] = useState(false);
    const [previewPrice, setPreviewPrice] = useState(0);
    let { id } = useParams();
    const [ref, inView] = useInView({threshold: 0.5});
    const [showBuyoutConfirm, setShowBuyoutConfirm] = useState(false);
    const [showBidConfirm, setShowBidConfirm] = useState(false);
    const [hide, setHide] = useState(false);
    const closeModal = () => setRelist(false);
    const closeModal2 = () => {setShowBidConfirm(false); setPreviewPrice(0)};
    const closeModal3 = () => setShowBuyoutConfirm(false);
    const self_bid = false;

    async function handleSubmit(event) {
       
        event.preventDefault();
        setShowSubmit(false);
    
        var plateData = {};
    
        if(event.target.sprice.value === '' || event.target.plate.value === ''){
          setStatus3("You must enter all the required information.");
          setShowSubmit(true);
          return;
        }
    
        if(Number(event.target.mprice.value) < Number(event.target.sprice.value)){
          if(Number(event.target.mprice.value) > 0){
            setStatus3("The minimum price can't be less than the starting price.");
            console.log("less");
            setShowSubmit(true);
            return;
          }
        }
    
        plateData.url = data.url;
        plateData.duration = event.target.duration.value;
        plateData.startprice = event.target.sprice.value;
    
        if(event.target.mprice.value === ''){
          plateData.minimumprice = "0";
        }else{
          plateData.minimumprice = event.target.mprice.value;
        }
    
        if(event.target.mprice.value === ''){
          plateData.buyoutprice = "0";
        }else{
          plateData.buyoutprice = event.target.bprice.value;
        }
        
        plateData.uid = user.sub;
    
    
        console.log(JSON.stringify(plateData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(plateData),
            mode: 'cors'
        };
    
        await fetch(API_URL + "auctions/retryauction", requestOptions).then(
          response => response.json()
        ).then((result) => {
          //setStatus(result);
          if(result.includes('successfully')){
            window.location.reload();
          }else{
            setStatus3(result);
          }
        }).catch((error) => {
          setStatus3(error);
        });
    
        setShowSubmit(true);
      }

    const handleChange = (file) => {
        setFile(file);
        console.log(file.name);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    async function verifyAuction(event){
        event.preventDefault();
    }

    async function uploadLogbook(){
        var plateData = {};

        setShowBuy(false);

        if(file == null){
          setStatus2("You must upload a valid image/document file that displays your logbook");
          setShowBuy(true);
          return;
        }
    
        const base64 = await convertBase64(file)
        const parts = base64.split(",");

        plateData.by = user.sub;
        plateData.file = parts[1];
        plateData.for = data._id;
        plateData.file_name = file.name;

        console.log(JSON.stringify(plateData));
    
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(plateData),
            mode: 'cors'
        };
    
        await fetch(API_URL + "auctions/upload/logbook", requestOptions).then(
          response => response.json()
        ).then((result) => {
          setStatus2(result);
          if(result.includes('successfully')){
            setHide(true);
          }
        }).catch((error) => {
          setStatus2(error);
        });
    
        setShowBuy(true);
    }

    async function addToWatchlist(){
        var _data = {};

        _data.for = data._id;
        _data.by = user.sub;

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(_data),
            mode: 'cors'
        };

        await fetch(API_URL + "auctions/addwatch", requestOptions).then(
            response => response.json()
        ).then((result) => {
            setStatus(result);
            data.watched_by_count += 1;
        }).catch((error) => {
            console.log(error);
            //setStatus(error);
        });
    }

    async function buyoutAuction(event){
        event.preventDefault();

        setShowBuy(false);
        setStatus(null);
        setShowLoading(true);

        var bidData = {};

        bidData.for = data._id;
        bidData.room = data.url;
        bidData.by = user.sub;

        console.log(JSON.stringify(bidData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(bidData),
            mode: 'cors'
        };

        await fetch(API_URL + "auctions/buyout", requestOptions).then(
            response => response.json()
        ).then((result) => {
            setStatus(result);
            
            if(!result.includes("Error")){
                setStatusColor('#9dfaa5');
                setShowBuy(false);
                setWon(true);
                setStatus("You have successfully bought out this auction!")
            }else{
                setStatusColor('#ff9b99');
                setShowBuy(true);
                closeModal3();
            }
            
        }).catch((error) => {
            setStatusColor('#ff9b99');
            setShowBuy(true);
            setShowBuyoutConfirm(false);
            //setStatus(error);
        });
        
        setShowLoading(false);
    }

    async function retryPayment(event){
        setShowBuy(false);

        var payData = {};

        payData.for = data._id;
        payData.by = user.sub;

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(payData),
            mode: 'cors'
        };

        await fetch(API_URL + "auctions/retry", requestOptions).then(
            response => response.json()
        ).then((result) => {
            setStatus2(result);
        }).catch((error) => {
            setStatusColor('#ff9b99');
            setShowBuy(true);
            //setStatus(error);
        });
    }

    async function handleBid(event) {
        event.preventDefault();

        setStatus(null);
        setShowBuy(false);
        setShowBidConfirm(false);
        setShowLoading(true);

        var bidData = {};

        if (Number(event.target.bid.value) <= data.highest_bid) {
            setStatus("Your bid must be higher than the current highest bid.");
            setShowBuy(true);
            setStatusColor('rgb(253, 223, 167)');
            return;
        }

        bidData.for = data._id;
        bidData.by = user.sub;
        bidData.amount = Number(event.target.bid.value);
        bidData.name = user.nickname;
        bidData.room = data.url;

        console.log(JSON.stringify(bidData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(bidData),
            mode: 'cors'
        };

        await fetch(API_URL + "auctions/bid", requestOptions).then(
            response => response.json()
        ).then((result) => {
            setShowBuy(true);
            setStatus(result);
            if(result.includes("Error")){
                setStatusColor('#ff9b99');
            }else{
                setStatusColor('#9dfaa5');
            }
        }).catch((error) => {
            console.log(error);
            //setStatus(error);
        });

        setShowLoading(false);
    }

    async function getPlate() {
        var req;
        if(isAuthenticated){
            req = API_URL + `auctions/${user.sub}/${id}`

            var accessToken = await getAccessTokenSilently();
            const requestOptions = {
                method: 'GET',
                headers: {'authorization': 'Bearer ' + accessToken},
                mode: 'cors'
            };

            const request = await fetch(req, requestOptions, {
            }).then((request => request.json())).then((data) => {
                setData(data)
                //setLoading(false)
                console.log(data);
                setImgs(imgs => [...imgs,"https://myregplates.s3.eu-west-2.amazonaws.com/" + data.cert_id] );
                setHighBid(data.highest_bid);
            }).catch(err => console.log(err))

           // document.title = data.name + " | MyRegPlates";
        }else{
            req = API_URL + `auctions/no_auth/no_auth/${id}`
            const request = await fetch(req, {
            }).then((request => request.json())).then((data) => {
                setData(data)
                //setLoading(false)
                console.log(data);
                setImgs(imgs => [...imgs,"https://myregplates.s3.eu-west-2.amazonaws.com/" + data.cert_id] );
                setHighBid(data.highest_bid);
            }).catch(err => console.log(err))

            //document.title = data.name + " | MyRegPlates";
        }

    }

    async function getBids() {
        const request = await fetch(API_URL + `auctions/get/bids/${data._id}`, {

        }).then((request => request.json()))
        .then((data2) => {
            setData2(data2)
            setLoading(false)
            console.log("connect (380)");
            connectToRoom();
        }).catch(err => console.log(err))
    }

    async function getUserInfo(){
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
            mode: 'cors'
        };

        const request = await fetch(API_URL + `payment/info/${user.sub}`, requestOptions, {

        }).then((request => request.json()))
          .then((data) => {
            setData3(data)
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })
    }

    async function shareLink(){
        navigator.clipboard.writeText(`https://www.myregplates.com/plates/${data.url}`);
        setStatus("Link copied to clipboard!");
    }

    async function connectToRoom(){
        const socket = io(WS_URL);
        await socket.connect();
        socket.emit('joinroom', data.url);
        socket.on('bid', function (data) {
            setData2(oldArray => [...oldArray,data] );
            
            if(data.new_exp){
                setData((prevState) => {
                    return({
                      ...prevState,
                      expires_on: data.new_exp
                    });
                });
            }

            setHighBid(data.amount);
        });

        socket.on('plate', function (data) {
            setData(oldArray => [data,...oldArray] );
        });

        socket.on('end', async function (data) {
            setData((prevState) => {
                return({
                    ...prevState,
                    status: 'finished'
                });
            });

            setEnd(true);
           // console.log(`end`);
           // console.log(bids.current);

            if(bids.current.length > 0){
                if(bids.current[bids.current.length - 1].by_nick === user.nickname){
                    window.location.reload();
                }
            }
        });
    }

    function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    function getTotalPrice(price){
        // NO TAX FOR NOW
        //return price + (price * 0.10) + (price * 0.20) + 80;
        return price + (price * 0.10) + 80;
    }

    function setPreview(event){
        if(isNumber(event.target.value)){
            var price = Number(getTotalPrice(Number(event.target.value)));
            price = Math.ceil(price);

            setPreviewPrice(price);
        }else{
            setPreviewPrice(0);
        }
    }

    function is_number(char) {
        return !isNaN(parseInt(char));
    }

    function getCurrentFormatYear(year){
        var daymonth = '';

        if(year > 50){
            daymonth = '1 September '
        }else{
            daymonth = '1 March '
        }

        if(year < 51){
            daymonth += '20' + year;
        }else{
            var y = (year - 50);
            if(y < 10){
                daymonth += '20' + '0' + y;
            }else{
                daymonth += '20' + y;
            }
        }

        return daymonth;
    }

    function get2001FormatYear(year){
        switch (year){
            case 'A': return '1 August 1983'; break;
            case 'B': return '1 August 1984'; break;
            case 'C': return '1 August 1985'; break;
            case 'D': return '1 August 1986'; break;
            case 'E': return '1 August 1987'; break;
            case 'F': return '1 August 1988'; break;
            case 'G': return '1 August 1989'; break;
            case 'H': return '1 August 1990'; break;
            case 'J': return '1 August 1991'; break;
            case 'K': return '1 August 1992'; break;
            case 'L': return '1 August 1993'; break;
            case 'M': return '1 August 1994'; break;
            case 'N': return '1 August 1995'; break;
            case 'P': return '1 August 1996'; break;
            case 'R': return '1 August 1997'; break;
            case 'S': return '1 August 1998'; break;
            case 'T': return '1 March 1999'; break;
            case 'V': return '1 September 1999'; break;
            case 'W': return '1 March 2000'; break;
            case 'Y': return '1 September 2000'; break;
            case 'Z': return '1 March 2001'; break;
        }
    }

    function get1983FormatYear(year){
        switch (year){
            case 'A': return '1 February 1963'; break;
            case 'B': return '1 January 1964'; break;
            case 'C': return '1 January 1965'; break;
            case 'D': return '1 January 1966'; break;
            case 'E': return '1 January 1967'; break;
            case 'F': return '1 August 1967'; break;
            case 'G': return '1 August 1968'; break;
            case 'H': return '1 August 1969'; break;
            case 'J': return '1 August 1970'; break;
            case 'K': return '1 August 1971'; break;
            case 'L': return '1 August 1972'; break;
            case 'M': return '1 August 1973'; break;
            case 'N': return '1 August 1974'; break;
            case 'P': return '1 August 1975'; break;
            case 'R': return '1 August 1976'; break;
            case 'S': return '1 August 1977 '; break;
            case 'T': return '1 August 1978'; break;
            case 'V': return '1 August 1979'; break;
            case 'W': return '1 August 1980 '; break;
            case 'Y': return '1 August 1981'; break;
            case 'Z': return '1 August 1982'; break;
        }
    }

    function isCurrentFormat(plate){
        if(!is_number(plate.charAt(0)) && !is_number(plate.charAt(1)) && is_number(plate.charAt(2)) && is_number(plate.charAt(3))){
            return true;
        } else {
            return false;
        }
    }

    function is2001Format(plate){
        if(!is_number(plate.charAt(0)) && is_number(plate.charAt(1)) && is_number(plate.charAt(2)) && is_number(plate.charAt(3))){
            return true;
        } else {
            return false;
        }
    }

    function is1983Format(plate){
        if(is_number(plate.charAt(3)) && is_number(plate.charAt(4)) && is_number(plate.charAt(5)) && !is_number(plate.charAt(6))){
            return true;
        } else {
            return false;
        }
    }

    function setDate(){
        var date = '';
        var plate = data.name.replace(/\s/g, '');
        if(plate.length === 7){
            if(isCurrentFormat(plate)){
                var year = plate.charAt(2) + plate.charAt(3);
                var f = getCurrentFormatYear(Number(year));
                date = f;
            }else if(is2001Format(plate)){
                var year = plate.charAt(0);
                var f = get2001FormatYear(year);
                date = f;
            }else if(is1983Format(plate)){
                var year = plate.charAt(6);
                var f = get1983FormatYear(year);
                date = f;
            }
        }
        
        setPlateDate(date);
    }

    useEffect(() => {
        bids.current = data2;
    }, [data2]);

    useEffect(() => {
        getPlate();
    }, [isAuthenticated]);

    useEffect(() => {
        if(isAuthenticated){
            getUserInfo();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        getBids();
        if(data){
            document.title = `${data.name} | MyRegPlates`
            setDate();
        }
    }, [data]);

    useEffect(() => {
        //console.log(inView);
    }, [inView]);


    return (
        <div id="plate-view-wrapper">
            <div id="plate-view">
                {loading ? (
                    <Loading></Loading>
                ) : (
                    data.name === '500_UNAUTHORIZED' ? (
                        <NotFound />
                    ):(
                    <>
                    <div class="plate-view-sub pv-top">
                        {!inView ? ( 
                            <div className="plate-mobile-header">
                                <div className="small-plate">
                                    {data.name}
                                </div>
                            </div>
                        ):(
                            null
                        )}
                        <div class="plate-container-left">
                            {data.sold_to && data.status === 'finished_payment_failed' ? (
                                <div class="plate-gallery-wrapper top-notification tp-error">
                                    <h2>Payment Failed!</h2>
                                    <p>You have won the auction but your payment method has declined. Please visit your user profile and enter a valid card and click the button below to retry the payment. <br>
                                    </br>If you do not successfully complete the payment within three days, you will lose the auction. <br></br>
                                    {showBuy ? (
                                        <button className="theme-button account-button" style={{marginTop: "1rem"}} onClick={() => retryPayment()}>Retry Payment</button>
                                    ):(
                                        <Loading></Loading>
                                    )}

                                    {status2 ? (
                                        <p>{status2}</p>
                                    ):(
                                        null
                                    )}

                                    <br/>
                                    <br/>

                                    <Link to="/support"><b>Need help?</b></Link>
                                    </p>
                                </div>
                            ):(
                                null
                            )}

                            {data.sold_to && (data.status === 'finished_won' || data.status === 'finished_buyout' || data.status === 'finished_retried')  ? (
                                <div class="plate-gallery-wrapper top-notification tp-ok">
                                    <h2>Auction Complete</h2>

                                    {!data?.post ? (
                                        <>
                                        <p>You successfully won this auction on {moment(data.sold_at).format('DD MMMM YYYY')} <br></br> 
                                        MyRegPlates can only transfer your new plate to you if you are ready to assign to a vehicle. To do this, please upload a file clearly showing the log book for the vehicle you wish to be assigned this plate. <br></br>
                                    
                                        </p> 
                                        </>
                                    ):(
                                        <>
                                        <p>You successfully won this auction on {moment(data.sold_at).format('DD MMMM YYYY')} <br></br> 
                                        You have successfully uploaded the logbook for the vehicle that this plate should be assigned to.
                                        </p>
                                        </>
                                    )}

                                    {!hide && !data.post ? (
                                        <>
                                        <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes="drop-zone" label="Upload or drop a file here" />
                                        {showBuy ? (
                                            <button className="theme-button account-button" style={{marginTop: "1rem"}} onClick={() => uploadLogbook()}>Upload</button>
                                        ):(
                                            <Loading></Loading>
                                        )}
                                        </>
                                    ):(
                                        null
                                    )}

                                    {status2 ? (
                                        <p style={{fontWeight: "bold", padding: "1rem", paddingLeft: "0"}}>{status2}</p>
                                    ):(
                                        null
                                    )}

                                    <br/>
                                    <br/>

                                    <Link to="/support"><b>Need help?</b></Link>

                                </div>
                            ):(
                                null
                            )}

                            {data.created_by && (data.status === 'finished_won' || data.status === 'finished_buyout' || data.status === 'finished_retried')  ? (
                                <div class="plate-gallery-wrapper top-notification tp-ok">
                                    <h2>Auction Complete</h2>
                                    <p>This auction was successfully finished on {moment(data.sold_at).format('DD MMMM YYYY')}. You will receive your funds once the plate is transferred to the buyers vehicle (this may take up to a week).<br></br>
                                    <br/>
                                    <Link to="/support"><b>Need help?</b></Link>
                                    </p>
                                </div>
                            ):(
                                null
                            )}

                            {user?.nickname === data2.slice().reverse().at(0)?.by_nick && !data.status.includes('finished') && isAuthenticated ? (
                                <div class="plate-gallery-wrapper top-notification tp-ok">
                                <h2>You are the top bidder!</h2>
                                <p>You hold the top bid for this plate and will win the auction if no one places a higher bid and the reserve price is met.
                                </p>
                                </div>
                            )
                            :(user?.nickname === data2.slice().reverse().at(1)?.by_nick && !data.status.includes('finished') && isAuthenticated ? (
                                <div class="plate-gallery-wrapper top-notification tp-error">
                                <h2>You have been outbid!</h2>
                                <p>Someone has outbid you for this plate.
                                </p>
                                </div>
                            ):(null))}

                            {data.created_by && (data.status === 'finished_below_min')  ? (
                                <div class="plate-gallery-wrapper top-notification tp-warn">
                                    <h2>Auction Finished</h2>
                                    <p>This auction was finished on {moment(data.sold_at).format('DD MMMM YYYY')} but a buyer was not found. You can re-list the auction by clicking the button below.</p>
                                    <button className='theme-button place-bid' style={{marginLeft: 0, marginTop: "1rem"}} onClick={() => setRelist(true)}>Relist</button>
                                    <Popup open={relist} closeOnDocumentClick onClose={closeModal}>
                                        <div className="modal-relist-wrapper" id="form-section">
                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "2rem"}}>
                                                <h1 style={{fontWeight: "900"}}>Confirm re-listing details</h1>
                                                
                                            </div>
                                            <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                <label>
                                                <span>Registration Plate <span style={{color: "red"}}>*</span></span>
                                                <input type="text" name="plate" className="plate-input" disabled={true} value={data.name}></input>
                                                </label>

                                                <label>
                                                <span style={{paddingTop: "1rem"}}>Auction Duration <span style={{color: "red"}}>*</span></span>
                                                <select name="duration" className="duration-input">
                                                    <option value="1">1 Week</option>
                                                    <option value="2">2 Weeks</option>
                                                    {/*<option style={{color: "red", fontWeight: "bold"}} value="3">5 Minutes (DEV ONLY)</option>*/}
                                                </select>
                                                </label>

                                                <label>
                                                <span style={{paddingTop: "1rem"}}>Starting Price <span style={{color: "red"}}>*</span></span>
                                                <input type="number" name="sprice" className="price-input" defaultValue={data.start_price}></input>
                                                </label>

                                                <label>
                                                <label for="tosinput" className="tos-label" style={{fontSize: "1rem"}}>Reserve Price</label>
                                                <span style={{color: "gray", fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.5rem"}}>Your plate will not sell if the auction doesn't reach the reserve price.</span>
                                                <input type="number" name="mprice" className="price-input" defaultValue={data.minimum_price}></input>
                                                </label>

                                                <label>
                                                <label for="tosinput" className="tos-label" style={{fontSize: "1rem"}}>'Buy Now' Price</label>
                                                <span style={{color: "gray", fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.5rem"}}>Your plate can be bought immediately for the set price.</span>
                                                <input type="number" name="bprice" className="buyout-input" defaultValue={data.buyout_price}></input>
                                                </label>

                                                {showSubmit ? (
                                                <div style={{display: "flex", alignItems: "center", marginTop: "2rem", justifyContent: "center"}}>
                                                    <input style={{marginTop: "0"}} type="submit" name="submit" className="submit-input" text="Submit"></input>
                                                    <button type="button" style={{background: "none", border: "none", fontWeight: "bold", marginLeft: "1rem", cursor: "pointer"}} onClick={() => setRelist(false)}>Go Back</button>
                                                </div>
                                                ):(
                                                <Loading></Loading>
                                                )}
                                                
                                            </form>

                                                {apiStatus3 ? (
                                                    <>
                                                    <p className="card-status">{apiStatus3}</p>
                                                    <div style={{height: 20}}>

                                                    </div>
                                                    </>
                                                ):(
                                                    null
                                                )}
                                        </div>
                                    </Popup>
                                </div>
                            ):(
                                null
                            )}

                            {data.created_by && (data.status === 'unverified_declined')  ? (
                                <div class="plate-gallery-wrapper top-notification tp-error">
                                    <h2>Auction Declined</h2>
                                    <p>MyRegPlates has decided to decline this auction. <br></br> <Link to="/support">Need help?</Link></p>
                                </div>
                            ):(
                                null
                            )}

                            <div class="sub-left">
                                <div id="main-plate" ref={ref}>
                                    {data.name}
                                </div>
                                
                                {data.status !== 'unverified' ? (
                                    <>
                                    <p style={{textAlign: "center", paddingTop: "1rem" , color: "#aaaaaa"}}>This plate is verified by MyRegPlates</p>

                                    {data.description?.length > 0 ? (
                                        <div className="plate-desc-wrapper">
                                            <p className="plate-desc">{data.description}</p>
                                        </div>
                                    ):(
                                        null
                                    )}

                                    </>
                                ):(
                                    null
                                )}
                            </div>

                            <div class="sub-right">   
                                <div class="plate-container-left bid-section">
                                    <div className="plate-bid-section-header">
                                        <h2 className="info-header">Auction Info</h2>
                                        <div className="action-icon-container">
                                                <button className="action-icon" onClick={() => addToWatchlist()}>
                                                    {/*<p style={{marginRight: "0.5rem", fontWeight: "bold", color: "white", opacity: "0.5"}}>({data.watched_by_count})</p>*/}
                                                    <img src={eyeIcon} alt="Add to Watchlist" width="32" height="32"></img>
                                                </button>

                                                <button className="action-icon" onClick={() => shareLink()}>
                                                    <img src={shareIcon} alt="Share" width="32" height="32"></img>
                                                </button>
                                        </div>
                                    </div>

                                    <div class="plate-info">
                                        <p className="info-text"><b>Plate: </b> <br></br>{data.name?.toUpperCase()}</p>
                                        
                                        <div className="info-text-wrapper">
                                            <p className="info-text info-text-wrapped"><b>Seller:</b></p>
                                            <div className="icon-text-align">
                                                <p>{data.created_by_nick}</p>
                                                {data.verified_seller ? (
                                                    <a data-tooltip-id="verified-tooltip" data-tooltip-content="This user has previously sold a plate with MyRegPlates">
                                                        <img className="verified-icon"src={verified} width={16} height={16}/>
                                                    </a>
                                                ):(
                                                    null
                                                )}           
                                            </div>
                                        </div>

                                        <Tooltip id="verified-tooltip" />
                                        
                                        <p className="info-text"><b>Auction started: </b> <br></br>
                                            {data.status.includes('unverified') ? (
                                                <p>Pending</p>
                                            ):(
                                                <>
                                                {data?.relisted_on ? (
                                                    <>
                                                    {moment(data?.relisted_on).format('DD MMMM YYYY')}
                                                    </>
                                                ):(
                                                    <>
                                                    {moment(data.created_on).format('DD MMMM YYYY')}
                                                    </>
                                                )}
                                                </>  
                                                
                                            )}
                                        </p>

                            
                                    </div>

                                    {!data.status.includes('unverified') ? (
                                        <div id="auction-info">
                                            <div className='info-container'>
                                                <p>Starts At: <span className="info-important">£{data.start_price.toLocaleString()}</span> </p>  
                                                {data.buyout_price > 0 ? (
                                                    <span className="info-sub">(Buy Now: £{data.buyout_price.toLocaleString()})</span>
                                                ):(
                                                    null
                                                )}
                                            </div>

                                            <div className='info-container'>
                                                <p>Top Bid: <span className="info-important highest-bid" key={Math.random()}>£{highBid.toLocaleString()}</span> </p>  
                                                {data.minimum_price > 0 ? (
                                                    highBid < data.minimum_price ? (
                                                        <div className='icon-text-align' style={{display: "block"}}>
                                                        <span className="info-sub" style={highBid < data.minimum_price ? {color: 'darkred', fontWeight: "bold"}:{}}>
                                                        Reserve Not Met
                                                        </span>
                                                        <a data-tooltip-id="reserve-tooltip" data-tooltip-content="This auction won't sell if it doesn't reach the reserve price">
                                                        <img className="verified-icon"src={qm} width={16} height={16}/>
                                                        </a>
                                                        </div>
                                                    ):(
                                                        <span className="info-sub">
                                                        Reserve Met
                                                        </span>
                                                    )
                                                        
                                                ):(
                                                    null
                                                )}
                                            </div>

                                            <Tooltip id="reserve-tooltip" />

                                            {!data.status.includes('finished') ? (
                                                <div className='info-container'>
                                                    <p>Time Left: <span className="info-important"><Countdown date={Date.parse(`${data.expires_on}`)} /></span></p>
                                                    <span className="info-sub">(Ends: {moment(data.expires_on).format('DD MMMM YYYY HH:mm')})</span>
                                                </div>
                                            ):(
                                                null
                                            )}
                                                
                                        </div>
                                    ):(
                                        null
                                    )}

                                    <div className="bid-history">
                                        <div className="bid-history-header controls-before-container">
                                        {!data.status.includes('finished') ? (
                                                <>
                                                        {((data.created_by !== user?.sub) || self_bid) && showBuy && isAuthenticated && data3.card && !data3.delinquent && data.status !== 'unverified' ? (
                                                            <div id="controls">
                                                                {!showBuyoutConfirm && !showBidConfirm ? (
                                                                    <div>
                                                                        <button onClick={() => setShowBidConfirm(true)} className="confirm-button">Place Bid</button>

                                                                        {data.buyout_price > 0 ? (
                                                                            <button onClick={() => setShowBuyoutConfirm(true)} className="confirm-button">Buy Now</button>
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                    </div>
                                                                ):(
                                                                    showBidConfirm ? (
                                                                        <Popup open={showBidConfirm} onClose={closeModal2}>
                                                                        <form className="bid-form" onSubmit={handleBid}>
                                                                            <div className="bid-popup-container">
                                                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                                                    <h2 style={{paddingBottom: "1rem"}}>Place your bid</h2>
                                                                                    <button onClick={() => {setShowBidConfirm(false); setPreviewPrice(0)}} className="close-popup">×</button>
                                                                                </div>

                                                                                {showBuy ? (
                                                                                <div className="bid-popup-controls">
                                                                                    <input className="bid-amount-input" type="text" name="bid" placeholder="Bid amount" onChange={(e) => setPreview(e)}></input>
                                                                                    <input type="submit" value="Place Bid" className="theme-button place-bid"></input>
                                                                                    
                                                                                </div>
                                                                                ):(
                                                                                    <Loading></Loading>
                                                                                )}
   
                                                                                {previewPrice > 0 ? (
                                                                                    <>
                                                                                    <p style={{paddingTop: "1rem"}}>Total Price (including fees): £{previewPrice.toLocaleString()}<Link to="/faq"><b> (Learn more)</b></Link></p>
                                                                                    </>
                                                                                ):(
                                                                                    null
                                                                                )}

                                                                                <p style={{padding: "1rem 0", paddingTop: "1rem", color: "gray", paddingBottom: "0"}}>By submitting a bid, you give MyRegPlates the permission to transfer the plate to your vehicle on your behalf.</p>
                                                                               
                                                                            </div>
                                                                        </form>
                                                                        </Popup>
                                                                    ):(
                                                                        <Popup open={showBuyoutConfirm} onClose={closeModal3}>
                                                                            <form onSubmit={buyoutAuction} style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                                                                <div className="bid-popup-container">
                                                                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                                                                        <h2 style={{paddingBottom: "0rem"}}>Buy Now</h2>
                                                                                        <button onClick={() => setShowBuyoutConfirm(false)} className="close-popup">×</button>
                                                                                    </div>
                                                                                    <div className="fees">
                                                                                        <p style={{padding: "1rem", fontWeight: "bold"}}>Are you sure you want to buy this auction immediately?</p>
                                                                                        <table className="fee-table">
                                                                                            <tr>
                                                                                                <td>Auction Price</td>
                                                                                                <td className="fees-value">£{data.buyout_price}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>MyRegPlates Fee</td>
                                                                                                <td className="fees-value">£{(data.buyout_price * 0.10)}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>DVLA Fee</td>
                                                                                                <td className="fees-value">£{80}</td>
                                                                                            </tr>
                                                                                            {/*
                                                                                            <tr>
                                                                                                <td>VAT</td>
                                                                                                <td className="fees-value">£{data.buyout_price * 0.20}</td>
                                                                                            </tr>
                                                                                             */}
                                                                                            <tr>
                                                                                                <td><h3>Total</h3></td>
                                                                                                <td className="fees-value"><h3>£{getTotalPrice(data.buyout_price).toLocaleString()}</h3></td>
                                                                                            </tr>
                                                                                        </table>

                                                                                        <p style={{maxWidth: "400px", margin: "0 auto", padding: "1rem", color: "gray", textAlign: "center"}}>By buying this auction you grant MyRegPlates permission to transfer the license plate to your chosen vehicle on your behalf.</p>

                                                                                    </div>
                                                                                    {showBuy ? (
                                                                                        <div className="bid-popup-controls" style={{margin: "0 auto"}}>
                                                                                            <input type="submit" value={`Confirm`} className="theme-button theme-button-mobile buy-now"></input>
                                                                                            <button onClick={() => setShowBuyoutConfirm(false)} className="theme-button theme-button-mobile buy-now reverse-button">Go Back</button>
                                                                                        </div>
                                                                                    ):(
                                                                                        <Loading></Loading>
                                                                                    )}
                                                                                </div>
                                                                            </form>
                                                                        </Popup>
                                                                    )
                                                                )}
                                                            </div>
                                                      
                                                        ):(
                                                            !won ? (
                                                                data.card ? (
                                                                    <Loading></Loading>
                                                                ):(
                                                                    showLoading ? (
                                                                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                                                            <Loading></Loading>
                                                                        </div>
                                                                    ) : (
                                                                        null
                                                                    )
                                                                )
                                                            ):(
                                                                null
                                                            )  
                                                        )}
                                                        
                                                   
                                                </>
                                            ):(
                                                null
                                            )}
                                        </div>

                                        {data.status.includes('finished') || end ? (
                                            <>
                                            <div class="status-box">
                                                <p style={{textAlign: "center" }}><b>This auction is finished</b></p>
                                            </div>
                                            </>
                                        ):(
                                            null
                                        )}

                                        {!data3.card && isAuthenticated && user.sub !== data.created_by ? (
                                            <div class="status-box" style={{backgroundColor: `rgb(253, 223, 167)`}}>
                                                <p style={{textAlign: "center" }}><b>You can't place bids until you provide a valid bank card</b></p>
                                                <p style={{textAlign: "center", textDecoration: "underline"}}><a href="/my/profile?view=payment">Visit your profile page to add a card</a></p>
                                            </div>
                                        ):(
                                           null
                                        )}

                                        {data3.delinquent && isAuthenticated ? (
                                            <div class="status-box" style={{backgroundColor: `rgb(253, 223, 167)`}}>
                                                <p style={{textAlign: "center" }}><b>You can't place bids until you resolve your pending payment issue</b></p>
                                                <p style={{textAlign: "center", textDecoration: "underline"}}><a href="/faq?q=8">Need help?</a></p>
                                            </div>
                                        ):(
                                           null
                                        )}

                                        {data.status === 'unverified' ? (
                                            <div class="status-box" style={{backgroundColor: `rgb(253, 223, 167)`}}>
                                                <p style={{textAlign: "center" }}><b>This auction is pending verification.</b></p>
                                                <p style={{textAlign: "center", textDecoration: "underline"}}><a href="/faq?q=7">Need help?</a></p>
                                            </div>
                                        ):(
                                           null
                                        )}

                                        {!isAuthenticated && !end && !data.status.includes('finished') ? (
                                            <div class="status-box" style={{backgroundColor: `white`}}>
                                                <button onClick={() => loginWithRedirect()} style={{cursor: "pointer", width: "100%", background: "none", border: "none", fontWeight: "bold", fontSize: "1rem", margin: "0 auto", textDecoration: "underline", textAlign: "center", color: "black"}}>Log in or sign up to bid on this auction!</button>
                                            </div>
                                        ):(
                                            null
                                        )}
                                        
                                        {apiStatus ? (
                                            <div class="status-box" style={{backgroundColor: `${statusColor}`}}>
                                                <p style={{textAlign: "center" }}><b>{apiStatus}</b></p>
                                                {won ? (
                                                    <p style={{textAlign: "center", textDecoration: "underline"}}><a href="/help-auction-won">What happens next?</a></p>
                                                ):( 
                                                    null
                                                )}
                                            </div>
                                        ) : (
                                            null
                                        )}
                                        
                                    </div>

                                    <div className="bid-table-header">
                                        <h3 className="bid-count" key={Math.random()}>Bids ({data2.length}) 
                                       
                                        </h3>
                                    </div>

                                    <div className="bid-history table-overflow-scrollable">
                                        
                                        {data2.length > 0 ? (
                                            <>
                                                <table className="bid-history-table">
                                                    <tbody>
                                                        {data2.slice().reverse().map(bid_item => (
                                                            <tr className="bid-history-item" key={bid_item._id}>
                                                                <td style={{minWidth: "160px"}}><b>By: </b> {bid_item.by_nick}</td>
                                                                <td style={{minWidth: "160px"}}><b>Amount: </b> £{bid_item.amount.toLocaleString()}</td>
                                                                <td style={{minWidth: "160px"}}><b>Date: </b> {moment(bid_item.date).format('DD MMMM YYYY HH:mm:ss')}</td>
                                                                {bid_item?.extend ? (
                                                                    <td style={{minWidth: "160px"}}>
                                                                        <a data-tooltip-id="extended-tooltip" data-tooltip-content="This bid extended the auction by 15 minutes">
                                                                            <img className="verified-icon"src={clock} width={16} height={16}/>
                                                                        </a>
                                                                    </td>
                                                                ):(
                                                                    null
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                <Tooltip id="extended-tooltip" />
                                            </>
                                        ) : (
                                            <p style={{ padding: "1rem" }}>There are no bids for this auction yet.</p>
                                        )}
                                    </div>

                                    {data.status !== 'unverified' && !data?.h ? (
                                        <div class="plate-gallery-wrapper">
                                            <div className="bid-table-header">     
                                                <h3 className="bid-count" >Plate Certificate</h3>
                                            </div>
                                            
                                            <img className="gallery-img"
                                                src={ "https://myregplates.s3.eu-west-2.amazonaws.com/" + data.cert_id }
                                                onClick={ () => setIsViewerOpen(true) }
                                                width="200"
                                                style={{ marginTop: '1rem', maxWidth: "200px", maxHeight: "200px" }}
                                                alt="certificate"
                                            />
                                            {isViewerOpen && (
                                                <ImageViewer
                                                backgroundStyle={{zIndex: "500"}}
                                                src={ imgs }
                                                currentIndex={ 0 }
                                                disableScroll={ false }
                                                closeOnClickOutside={ true }
                                                onClose={ () => setIsViewerOpen(false) }
                                                />
                                            )}
                                        </div>
                                    ):(
                                        null
                                    )}
                                    <div class="plate-gallery-wrapper">

                                        <div className="bid-table-header">     
                                            <h3 className="bid-count" >Buyer Information</h3>
                                        </div>
                                        
                                        {!data.dateless && 1===0 ? (
                                            <p style={{padding: "1rem", lineHeight: "1.5rem"}} className="date-info">
                                                This plate can only be assigned to cars manufactured after: <b>{plateDate}</b>
                                            </p>
                                        ):(
                                            null
                                        )}
                                        
                                        <p style={{padding: "1rem", lineHeight: "1.5rem"}}>Buyers are subject to fees of 10% of the auction's final sale value plus an additional £80 DVLA fee. MyRegPlates can only grant ownership of the plate to the buyer by assigning it to a vehicle, this is to protect the buyer. The buyer must submit a valid logbook for the vehicle they wish the plate to be assigned to within seven days of winning the auction. By submitting a bid you grant MyRegPlates permission to transfer the number plate to your chosen vehicle on your behalf. <br></br>
                                        <a href="/faq" style={{fontWeight: "bold", textDecoration: "underline", margin: "1rem 0"}}>More info</a>
                                        </p>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    </>
                    )
                    
                )}
            </div>
        </div>
    )
}

export default Plate
