import React, { useEffect, useState } from 'react'
import { useParams } from "react-router";
import { API_URL, WS_URL, API_URL_ADMIN } from './common';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useAuth0, User } from "@auth0/auth0-react";
import './plate.css';
import './Admin.css'
import io from 'socket.io-client';
import saveIcon from './res/save.png'
import shareIcon from './res/share.png'
import eyeIcon from './res/eye.svg'
import ImageViewer from 'react-simple-image-viewer';
import { FileUploader } from "react-drag-drop-files";
import Loading from './Loading';
import { InView, useInView } from 'react-intersection-observer';
import { Tooltip } from 'react-tooltip';
import verified from './res/verified.png'

function ValuationAdmin() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [data2, setData2] = useState([]);
    const [apiStatus, setStatus] = useState();
    const [declineStatus, setDeclineStatus] = useState();
    const [highBid, setHighBid] = useState();
    const [file, setFile] = useState(null);
    const [viewDecline, setViewDecline] = useState(false);
    const [viewVerify, setViewVerify] = useState(false);
    const [viewUpload, setViewUpload] = useState(false);
    const { isAuthenticated, user, getAccessTokenSilently} = useAuth0();
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imgs, setImgs] = useState([]);
    const [ref, inView] = useInView({threshold: 0.5});
    let { id } = useParams();
    const fileTypes = ["JPG"];

    const handleChange = (file) => {
        setFile(file);
        console.log(file.name);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    async function handleSubmit(event){
        event.preventDefault();

        var plateData = {};

        plateData.price = event.target.price.value;
        plateData.target = data._id;
        plateData.plate = data.category;
        plateData.email = data.email;
        plateData.uid = data.uid;
        console.log(JSON.stringify(plateData));

        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + accessToken},
            body: JSON.stringify(plateData),
            mode: 'cors'
        };

        await fetch(API_URL_ADMIN + "valuations/submit", requestOptions).then(
            response => response.json()
            ).then((result) => {
                setDeclineStatus(result);
            }).catch((error) => {
                setDeclineStatus(error);
        });
    }


    async function getPlate() {
        var accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: {'authorization': 'Bearer ' + accessToken},
        };

        const request = await fetch(API_URL_ADMIN + `valuations/${id}`, requestOptions, {

        }).then((request => request.json()))
            .then((data) => {
                setData(data)
                setLoading(false)
                console.log(data);
            }).catch(err => console.log(err))
    }


    useEffect(() => {
        getPlate();
    }, []);


    return (
        <div id="plate-view-wrapper">
            <div id="plate-view">
                {loading ? (
                    <Loading></Loading>
                ) : (
                    data.name === '500_UNAUTHORIZED' ? (
                        <p>ADMIN: this plate doesn't exist</p>
                    ):(
                    <>
                    <div class="plate-view-sub pv-top">
                        {!inView ? ( 
                            <div className="plate-mobile-header">
                                <div className="small-plate">
                                    {data.category}
                                </div>
                            </div>
                        ):(
                            null
                        )}
                        <div class="plate-container-left">
                           

                            <div class="sub-left">
                                <div id="main-plate" ref={ref}>
                                {data.category}
                                </div>
                                
                            </div>

                            <div class="sub-right">   
                                <div class="plate-container-left bid-section">
                                    <div className="plate-bid-section-header">
                                        <h2 className="info-header">Auction Info</h2>
                                        <div className="action-icon-container">
                 
                                        </div>
                                    </div>

                                
                             
                                

                      
                                   
                          <div className="admin-section">
                        <h2>Admin</h2>
                   

                        <button onClick={() => setViewDecline(!viewDecline)} className="theme-button account-button">Value Plate</button>


                        {viewDecline ? (
                            <>
                            <h3>Plate value: </h3>
                            <form onSubmit={handleSubmit}>
                                <textarea name="price" type="text"></textarea>
                                <input className="submit-input" type="submit"></input>
                            </form>
                            </>
                        ):(
                            null
                        )}

                        {declineStatus ? (
                            <p style={{ paddingBottom: "1rem", textAlign: "center", fontWeight: "bold", backgroundColor: "white", border: "3px solid black" }}>{declineStatus}</p>
                        ) : (
                             null
                        )}

                    </div>

                    <p>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                    </p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    </>
                    )
                    
                )}
            </div>
        </div>
                    

    )
}

export default ValuationAdmin