import React, { useEffect, useState } from 'react'
import { API_URL } from './common';
import './plates.css'
import Table from './Table';
import Loading from './Loading';

function Plates() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    
    async function getPlates() {
        const request = await fetch(API_URL + `auctions`, {

        }).then((request => request.json()))
        .then((data) => {
            setData(data)
            setLoading(false) // set Loading to false when you have the data
        }).catch(err => console.log(err)).then(() => {
            //setLoading(true)
        })
    }

    useEffect(() => {
        getPlates();
        document.title = "Live Auctions | MyRegPlates";
        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', 'Browse our selection of registration plates on auction and place your bid today!')

        const el2 = document.querySelector("link[rel='canonical']");
        el2.setAttribute('href', 'https://myregplates.com/plates')
    }, []);


    return (
        <div id="plate-wrapper">
            <div id="plate-section">
                <div id="header-section">
                    <h1>Live Auctions</h1>
                </div>

                {loading ? (
                    <Loading/>
                ) : (
                    <Table data={data}></Table>
                )}
            </div>
        </div>
    )
}

export default Plates